import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'
import { TableService } from '../../table.service'
import { DefaultComponent, TDefaultComponentData } from '../default/default.component'

export type TButtonComponentData = {
  hasNotification?: () => boolean
  displayValue?: string
  actualValue?: string
  marks_disabled?: boolean
} & TDefaultComponentData

@Component({
  selector: 'oa-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent<T> extends DefaultComponent<T> {
  @Input() override row!: TButtonComponentData

  constructor(
    private tableActions: TableService<T>,
    protected override renderer: Renderer2,
    protected override hostElement: ElementRef
  ) {
    super(renderer, hostElement)
  }

  override get classes() {
    return this.getClasses(this.row, this.column?.buttonConfig?.textClasses ?? [])
  }

  onClick(event: MouseEvent | KeyboardEvent) {
    event.stopPropagation()
    this.tableActions.actionEvent.next({
      tableName: this.column.tableName ?? '',
      event: this.column.buttonConfig,
      data: this.row
    })
  }
}
