import { Address } from './address.model'

export class Customer {
  customerId: string
  parentCustomerId: string
  accountNumber: string
  sectorAccountNumber: string
  name: string
  address: Address
  telephone: string
  fax: string
  relationship: string
  accountType: string
  accountSubType: string
  partnerProgram: string
  isClbPartner: boolean
  hasCrossReference: boolean
  canOrder: boolean
  isDirect: boolean
  salesArea: string
  isCertified: boolean
  isLatam: boolean
  isCanada: boolean
  isFastTrackEligible: boolean
  isProExpressEligible: boolean
  isADSystemsEligible: boolean
  isRepublicEligible: boolean

  constructor() {
    this.customerId = null
    this.parentCustomerId = null
    this.accountNumber = null
    this.sectorAccountNumber = null
    this.name = null
    this.address = new Address()
    this.telephone = null
    this.fax = null
    this.relationship = null
    this.accountType = null
    this.accountSubType = null
    this.partnerProgram = null
    this.isClbPartner = false
    this.hasCrossReference = false
    this.canOrder = true
    this.isDirect = true
    this.salesArea = null
    this.isCertified = false
    this.isLatam = false
    this.isCanada = false
    this.isFastTrackEligible = false
    this.isProExpressEligible = false
    this.isADSystemsEligible = false
    this.isRepublicEligible = false
  }
}
