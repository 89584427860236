<div class="lib-prep-container">
  <div class="left">
    <mat-form-field>
      <mat-label>{{'FORMFIELDS.hingeQty' | translate}}</mat-label>
      <mat-select [(ngModel)]="quantity">
        <mat-option *ngFor="let c of [1, 2, 3, 4, 5]" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <lib-input-option
      label="Hinge Prep"
      [selectedItemValue]="code"
      (selectionChange)="code = $event"
      [disabled]="!quantity"
      transformType="preps"
      [optionsList]="codes"
    >
    </lib-input-option>
    <div class="lib-radio-group" *ngIf="!isContinuousHinge && quantity">
      <mat-label>{{ 'FORMFIELDS.isThisElectricHinge' | translate }}</mat-label>
      <mat-radio-group required name="isThisElectricHinge" [(ngModel)]="isElectricHinge">
        <mat-radio-button [value]="'Yes'" color="primary">{{ 'yes' | translate }}</mat-radio-button>
        <mat-radio-button [value]="'No'" color="primary">{{ 'no' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <lib-input-option
    *ngIf="!isContinuousHinge && electricHingeSelected"
    [label]="'FORMFIELDS.electricHingeLocation' | translate"
    [selectedItemValue]="prep.electricHingeLocation"
    (selectionChange)="prep.electricHingeLocation = $event; updateValue()"
    [optionsList]="electricHingeLocations"
    >
    </lib-input-option>

   <lib-input-option
    *ngIf="!isContinuousHinge && electricHingeSelected"
    [label]="'FORMFIELDS.locationWithinHinge' | translate"
    [selectedItemValue]="prep.locationWithinHinge"
    (selectionChange)="prep.locationWithinHinge = $event; updateValue()"
    [optionsList]="locationsWithinHinge"
    >
    </lib-input-option>

    <lib-input-option
      label="Location *"
      [selectedItemValue]="location"
      (selectionChange)="location = $event"
      [disabled]="!code"
      [optionsList]="locations"
      transformType="value"
    ></lib-input-option>
    <mat-form-field>
      <mat-label>{{'FORMFIELDS.referencePoint' | translate}}</mat-label>
      <mat-select
        [value]="referencePoint"
        (selectionChange)="referencePoint = $event"
        [disabled]="!code || location !== specialPrepLocation"
        [required]="true"
      >
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="code !== specialPrepCode">
      <mat-label>{{'FORMFIELDS.backset' | translate}}</mat-label>
      <mat-select [value]="backset" (selectionChange)="backset = $event" [disabled]="!code">
        <mat-option *ngFor="let c of backsets" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="frameElevation.isSteelcraft">
      <mat-label>{{'FORMFIELDS.highFrequency' | translate}}</mat-label>
      <mat-select [value]="highFrequency" (selectionChange)="highFrequency = $event" [disabled]="!code">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let c of highFrequencies" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </div>
  <lib-frame-prep
    *ngIf="frameElevation"
    [frameElevation]="frameElevation"
    [subTypes]="subTypes"
    [prepLocationInputsFilled]="prepLocationInputsFilled"
  >
    {{ isContinuousHinge && ('MESSAGES.continuousHingeFrame' | translate) }}
  </lib-frame-prep>
</div>
