import { Component, OnInit } from '@angular/core'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'oa-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {
  accessEmail: string
  supportEmail: string

  constructor() {}

  ngOnInit() {
    this.accessEmail = environment.accessEmail
    this.supportEmail = environment.supportEmail
  }
}
