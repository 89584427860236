<div class="user-info" mat-dialog-title>
  <span class="user-name">
    {{
      (authService.userProfile$ | async)?.given_name?.substring(0, 1) +
        (authService.userProfile$ | async)?.family_name?.substring(0, 1)
    }}
  </span>
  <div class="user-details">
    <p class="name">
      {{ (authService.userProfile$ | async)?.given_name }} {{ (authService.userProfile$ | async)?.family_name }}
    </p>
    <p class="email">
      <span class="material-symbols-outlined icon"> mail </span>
      {{ (authService.userProfile$ | async)?.email }}
    </p>
  </div>
</div>
<div class="user-actions" mat-dialog-content>
  <div *ngIf="(flags$ | async)?.admin" (click)="dialogRef.close(); router.navigate(['/admin'])" class="button">
    {{ 'admin' | translate }}
  </div>
  <div
    *ngIf="(flags$ | async)?.isIndirect"
    (click)="dialogRef.close(); router.navigate(['/profileInfo'])"
    class="button"
  >
    {{ 'profileInfo' | translate }}
  </div>
  <div class="language">
    <span>{{ 'languagePreference' | translate }}</span>
    <mat-select [value]="translateSvc.currentLang" (selectionChange)="changeLanguage($event.value)">
      <mat-option *ngFor="let language of languages" [value]="language.code">
        {{ language.name }}
      </mat-option>
    </mat-select>
  </div>
  <div class="default-branch" *ngIf="(customerSvc.isExternalUser$ | async) && multiBranchEnabled">
    <span>{{ 'defaultBranchOnStartup' | translate }}</span>
    <mat-select [value]="customerSvc.defaultBranchOnLogin" placeholder="{{ 'LABEL.select' | translate }}">
      <mat-option [value]="null" (click)="customerSvc.defaultBranchOnLogin = null">--</mat-option>
      <mat-option
        *ngFor="let branch of customerSvc.customerBranches"
        [value]="branch.accountId"
        (click)="customerSvc.defaultBranchOnLogin = branch.accountId"
      >
        {{ customerSvc.getBranchDisplayName(branch) }}
      </mat-option>
    </mat-select>
  </div>
  <div class="legal">
    <span>{{ 'reviewLegalInformation' | translate }}</span>
    <a href="https://profile.allegion.com/tos" target="_blank" rel="noopener">{{ 'legal' | translate }}</a>
  </div>
  <div (click)="dialogRef.close(); openThirdPartyLicenses()" class="button">{{ 'thirdPartyLicenses' | translate }}</div>
  <div class="logout-action button" (click)="dialogRef.close(); authService.logout()">
    <mat-icon>logout</mat-icon>
    {{ 'logout' | translate }}
  </div>
</div>
