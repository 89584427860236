import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'

import { map, takeUntil } from 'rxjs/operators'
import { FlagsService } from '../../services/flags/flags.service'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class Admin implements CanActivate {
  constructor(private flagsSvc: FlagsService, private router: Router) {}

  public canActivate(): Promise<boolean> {
    return this.hasRole().then((result) => {
      if (result) {
        return true
      } else {
        this.router.parseUrl('/unauthorized')
        return false
      }
    })
  }

  private async hasRole(): Promise<boolean> {
    const hasFlags$: Subject<boolean> = new Subject()
    return new Promise((resolve) => {
      this.flagsSvc.flags$
        .pipe(
          takeUntil(hasFlags$),
          map((flags) => {
            if (!flags) {
              return
            }
            hasFlags$.next(true)
            hasFlags$.complete()
            resolve(flags.admin)
          })
        )
        .subscribe()
    })
  }
}
