export const MimeTypes = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpeg: 'image/jpeg',
  jpg: '	image/jpeg',
  pdf: 'application/pdf',
  png: 'Portable Network Graphics',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip'
} as const

export type TMimeTypes = keyof typeof MimeTypes
