import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import { MaterialModule, OEOCommonModule, OEOTranslateLoader } from '@oeo/common'
import { AnalyticsDirective, AppInsightsModule } from '@overtur/app-insights'

import { TokenInterceptorService } from './interceptors/token-interceptor.service'

import { EstimatesDialogComponent } from '../estimates/components/estimates-dialog/estimates-dialog.component'
import { AddHardwareSetsDialogComponent } from '../hardwareSets/components/add-hardware-sets-dialog/add-hardware-sets-dialog.component'
import { NewHardwareSetDialogComponent } from '../hardwareSets/components/new-hardware-set-dialog/new-hardware-set-dialog.component'
import { ErrorModalComponent } from './components/error-modal/error-modal.component'
import { ProductsDialogComponent } from './components/products-dialog/products-dialog.component'
import { PhoneDirective } from './directives/phone.directive'
// eslint-disable-next-line max-len
import { HardwareSetComponent } from '../hardwareSets/components/hardware-set/hardware-set.component'
import { RequoteDialogComponent } from '../requestForQuote/components/requote-dialog/requote-dialog.component'
import { ReviseQuoteDialogComponent } from '../requestForQuote/components/revise-quote-dialog/revise-quote-dialog.component'
import { RfqFormComponent } from '../requestForQuote/components/rfq-form/rfq-form.component'
import { RfqSubmitDialogComponent } from '../requestForQuote/components/rfq-submit-dialog/rfq-submit-dialog.component'
import { AddressComponent } from './components/address/address.component'
import { FilterTriggerComponent } from './components/filter-trigger/filter-trigger.component'
import { FilterComponent } from './components/filter/filter.component'
import { HiddenFormSubmitterComponent } from './components/hidden-form-submitter/hidden-form-submitter.component'
import { KeyingDialogComponent } from './components/keying-dialog/keying-dialog.component'
import { DateDirective } from './directives/date.directive'

import { TrimDirective } from './directives/trim.directive'
import { UpperCaseDirective } from './directives/uppercase.directive'
// eslint-disable-next-line max-len
import { CrossReferenceDialogComponent } from '../cross-reference/components/crossReference-dialog/cross-reference-dialog.component'
import { ShareDialogComponent } from '../estimates/components/share-dialog/share-dialog.component'
//  Internationalization
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core'
import { UploadComponent } from './components/upload/upload.component'

import { MarkdownModule } from 'ngx-markdown'
import { LabelDirective } from './directives/label.directive'
// eslint-disable-next-line max-len
import { HollowMetalStockProductsDialogComponent } from '../estimates/components/hollow-metal-stock-products-dialog/hollow-metal-stock-products-dialog.component'
import { StorageService } from '../services/local-storage/local-storage.service'
import { SearchAndFilterService } from '../services/search-and-filter/search-and-filter.service'
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component'
import { NotificationBannerComponent } from './notification-banner/notification-banner.component'
import { Base64imagePipe } from './pipes/base64image/base64image.pipe'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    OEOCommonModule.forRoot(StorageService, SearchAndFilterService),
    HttpClientXsrfModule.withOptions({
      cookieName: '.AspNetCore.Session'
    }),
    MarkdownModule.forRoot({
      loader: HttpClient //  Will need 'sanitize: SecurityContext.NONE' when we upgrade to Angular 9 or above
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: OEOTranslateLoader,
        deps: [HttpClient]
      },
      isolate: true
    }),
    AppInsightsModule
  ],
  declarations: [
    AddHardwareSetsDialogComponent,
    AddressComponent,
    Base64imagePipe,
    CrossReferenceDialogComponent,
    ErrorModalComponent,
    EstimatesDialogComponent,
    FilterComponent,
    FilterTriggerComponent,
    HardwareSetComponent,
    HiddenFormSubmitterComponent,
    HollowMetalStockProductsDialogComponent,
    KeyingDialogComponent,
    LabelDirective,
    NewHardwareSetDialogComponent,
    NotificationBannerComponent,
    PhoneDirective,
    ProductsDialogComponent,
    RequoteDialogComponent,
    ReviseQuoteDialogComponent,
    RfqFormComponent,
    RfqSubmitDialogComponent,
    ShareDialogComponent,
    TrimDirective,
    UploadComponent,
    UpperCaseDirective,
    WarningDialogComponent,
    DateDirective
  ],
  providers: [
    TranslateStore,
    TranslateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  exports: [
    AddressComponent,
    AnalyticsDirective,
    Base64imagePipe,
    CrossReferenceDialogComponent,
    DateDirective,
    ErrorModalComponent,
    EstimatesDialogComponent,
    FilterComponent,
    FilterTriggerComponent,
    FormsModule,
    HiddenFormSubmitterComponent,
    HollowMetalStockProductsDialogComponent,
    HttpClientModule,
    LabelDirective,
    MarkdownModule,
    MaterialModule,
    NotificationBannerComponent,
    OEOCommonModule,
    PhoneDirective,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    TrimDirective,
    UploadComponent,
    UpperCaseDirective,
    WarningDialogComponent
  ]
})
export class SharedModule {
  lang = 'en'
  supportedLanguages = ['en', 'fr', 'es']

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        TranslateStore,
        TranslateService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptorService,
          multi: true
        }
      ]
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private translate: TranslateService, private storageService: StorageService) {
    translate.addLangs(this.supportedLanguages)
    translate.setDefaultLang('en')
    this.handleLocalization()
  }

  handleLocalization() {
    const languageSetting: string = this.storageService.getItem('i18n')
    if (languageSetting) {
      this.translate.use(languageSetting)
      this.lang = languageSetting
      return
    }
    let browserLanguage = this.translate.getBrowserLang()
    if (!browserLanguage) {
      return
    }
    browserLanguage = browserLanguage.slice(0, 2).toLocaleLowerCase()
    const isSupported = !!this.supportedLanguages.find((l) => browserLanguage === l)
    if (!isSupported) {
      return
    }
    this.translate.use(browserLanguage)
    this.lang = browserLanguage
  }
}
