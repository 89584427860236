<div class="lib-prep-container">
  <form #PrepFieldsForm="ngForm" class="left">
    <mat-form-field *ngIf="!!referencePoints">
      <mat-label>{{ 'FORMFIELDS.referencePoint' | translate }}</mat-label>
      <input
        matInput
        name="referencePoint"
        [(ngModel)]="referencePoint"
        required
        #referencePointInput
        [libValidValueValidator]="referencePoints"
        [matAutocomplete]="referencePointAuto"
      />
      <mat-autocomplete autoActiveFirstOption #referencePointAuto="matAutocomplete">
        <mat-option *ngFor="let option of referencePoints | libAutoCompleteFilter:referencePointInput.autocompleteValue" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'FORMFIELDS.conduit' | translate}}</mat-label>
      <input
        matInput
        #conduitInput
        [matAutocomplete]="conduitAuto"
        name="conduit"
        [(ngModel)]="prep.conduit"
        [libValidValueValidator]="conduitYesNo"
        required
      />
      <mat-autocomplete #conduitAuto="matAutocomplete">
        <mat-option *ngFor="let option of conduitYesNo|libAutoCompleteFilter:conduitInput.autocompleteValue" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </form>
  <lib-door-prep *ngIf="door" [door]="door" [prepLocationInputsFilled]="prepLocationInputsFilled">
    <p *ngIf="message">{{ message }}</p>
  </lib-door-prep>
</div>
