import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs';
import { uomSwitch } from '../../../../core/functions/uomSwitch';
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { FramePrepReferencePoint, HorizontalFramePrepReferencePoints } from '../../../enums/frame-prep-reference-point';
import { StickSubtype } from '../../../enums/stick-subtype';
import { IFrameQuantityPrep } from '../../../interfaces/preps/i-frame-quantity-prep';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';
import { FramePrepCategoryIds } from '../../../../core/enums/prep-category-ids';

@Component({
  selector: 'lib-closer-prep',
  templateUrl: './closer-prep.component.html',
  styleUrls: ['./closer-prep.component.scss', '../prep-base.component.scss'],
})
export class CloserPrepComponent extends PrepBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FramePrepComponent, { static: false }) framePrepComponent: FramePrepComponent;
  subTypes = [StickSubtype.Head];
  codes: IPrepCode[];
  private _code: IPrepCode;
  public get code(): IPrepCode {
    return this._code;
  }
  public set code(value: IPrepCode) {
    this._code = value;
    if (this.locations.length === 1 && !!this.code) {
      this.location = this.locations.first();
      return;
    }
    if (this.prepsComponent.data?.frame.prepLocationPreference && !this.location){
      this.location = this.locations.first(({value}) => value === this.prepsComponent.data.frame.prepLocationPreference);
    }
    this.updateValue();
  }
  private _quantity: number;
  public get quantity(): number {
    return this._quantity;
  }
  public set quantity(value: number) {
    this._quantity = value;
    if (this.locations.length === 1 && !!this.code) {
      this.location = this.locations.first();
      return;
    }
    this.updateValue();
  }

  public get prep(): IFrameQuantityPrep {
    return this.frameElevation.closerPrep;
  }
  public set prep(value: IFrameQuantityPrep) {
    this.frameElevation.closerPrep = value;
  }

  private _locations: IPrepCategoryLocation[];
  public get locations(): IPrepCategoryLocation[] {
    return this._locations;
  }
  public set locations(value: IPrepCategoryLocation[]) {
    this._locations = value;
  }
  private _location: IPrepCategoryLocation;
  get location(): IPrepCategoryLocation {
    return this._location;
  }
  set location(value: IPrepCategoryLocation) {
    this._location = value;
    if (this.prepsComponent.data?.frame.prepLocationPreference && !this.location){
      this.location = this.locations.first(({value}) => value === this.prepsComponent.data.frame.prepLocationPreference);
    }
    this.updateValue();
  }

  get referencePoints(): FramePrepReferencePoint[] {
    return HorizontalFramePrepReferencePoints;
  }
  private _referencePoint: FramePrepReferencePoint;
  get referencePoint(): FramePrepReferencePoint {
    return this._referencePoint;
  }
  set referencePoint(value: FramePrepReferencePoint) {
    this._referencePoint = value;
    this.updateValue();
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, FramePrepCategoryIds.CLOSER);
    if (!this.prep) {
      this.prep = {} as IFrameQuantityPrep;
    }
    this._quantity = this.prep.quantity ?? null;
    this.codes = this.codes.concat([this.specialPrepCode]);
    this._code =
      this.codes.first(c => c.id === this.prep.id) ??
      this.codes.find(({ code, description }) => code.trim().searchFor('CR') && description.trim().searchFor('CLOSER REINF')) ??
      null;
    this.locations = prepsComponent.configService.prepCategories
      .first(c => c.id === 3)
      .prepCategoryLocations.concat([this.specialPrepLocation]);
    this._location = this.locations.first(c => c.value === this.prep.locationType) ?? null;
    this._referencePoint =
      this.referencePoints.first(c => c === this.prep.referencePoint) ?? FramePrepReferencePoint.LL;
    this.setSpecial();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => this.drawPreps());
    this.updateValue();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  updateValue(): void {
    if (!this.quantity) {
      this.prep = {} as IFrameQuantityPrep;
      this._code = null;
      this._location = null;
      return this.draw$.next();
    }
    if (!this.code) {
      this._location = null;
      return this.draw$.next();
    }
    this.prep.id = this.code?.id;
    this.prep.code = this.code?.code;
    this.prep.pricingCategory = this.code?.pricingCategory;
    this.prep.locationType = this.location?.value;
    this.prep.fixedLocation = this.code?.fixedLocation;
    this.prep.referencePoint = this.referencePoint;
    if (!(this.prep.location instanceof Array)) {
      this.prep.location = [];
    }
    const locations = (this.prep.location as string[]).take(this.quantity);
    this.prep.location = locations.concat(
      new Array(this.quantity - locations.length).fill(
        uomSwitch('0', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure),
        0,
        this.quantity - locations.length
      )
    );
    this.prep.quantity = this.quantity;
    this.draw$.next();
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear();
    if (this.prep.locationType !== this.specialPrepLocation.value || !this.prep.quantity) {
      this.prep.location = Array.isArray(this.prep.location)? []: '0"'
      return;
    }
    for (let i = 0; i < this.quantity; i++) {
      this.prepLocationInputs.push(this.drawPrep(i, this.prep));
    }
  }
}
