import { Injectable } from '@angular/core'
import { User } from '@auth0/auth0-spa-js'
import { isEqual } from 'lodash'
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, filter } from 'rxjs'
import { Customer, Features } from '../../models'
import { CustomerService } from '../customer/customer.service'

@Injectable({
  providedIn: 'root'
})
export class FlagsService {
  #flags: BehaviorSubject<Features> = new BehaviorSubject(null)
  flags$: Observable<Features> = this.#flags.asObservable()

  getFlags(): Features {
    return this.#flags.getValue()
  }

  customer: Customer
  user: User

  constructor(private customerSvc: CustomerService) {
    combineLatest([
      this.customerSvc.customer$.pipe(
        filter((x) => !!x),
        distinctUntilChanged((x, y) => isEqual(x, y))
      ),
      this.customerSvc.authProfile$.pipe(
        filter((x) => !!x),
        distinctUntilChanged((x, y) => isEqual(x, y))
      )
    ]).subscribe(([customer, user]) => this.setFlags(customer, user))
  }

  setFlags(customer: Customer, user: User) {
    if (!customer || !user) return
    const flags = new Features(customer, user)
    this.#flags.next(flags)
  }
}
