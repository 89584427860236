<div class="content">
  <div class="container">
    <ng-container *ngTemplateOutlet="DoorSettings"></ng-container>
  </div>
</div>

<ng-template #DoorSettings>
  <div class="door-settings">
    <ng-container *ngIf="elevationContainsPreps">
      <span class="prepWarning">
        <mat-icon svgIcon="info"></mat-icon>
        {{ 'WARNINGS.yourElevationHasPreps' | translate }}
      </span>
      <br />
    </ng-container>

    <span>{{ 'INSTRUCTIONS.allRequiredFields' | translate }}</span>
    <br />
    <div class="row section-header">
      <span>{{ 'unitsOfMeasurement' | translate }}</span>
    </div>
    <mat-radio-group
      (change)="updateForm(doorElevationFormGroup.unitOfMeasure, $event.value)"
      [value]="doorElevationFormGroup.unitOfMeasure.value"
    >
      <mat-radio-button [value]="doorElevationFormGroup.unitOfMeasure.value">{{
        doorElevationFormGroup.unitOfMeasure.value | translate
      }}</mat-radio-button>
    </mat-radio-group>
    <div class="row section-header">
      <span class="padding top s3">{{ 'nominalDimensions' | translate }}</span>
    </div>
    <div class="row">
      <span>{{ 'INSTRUCTIONS.decimalOrFraction' | translate }}</span>
    </div>
    <div class="row padding top s7">
      <div class="col grow">
        <mat-form-field>
          <mat-label>{{ 'FORMFIELDS.width' | translate }}</mat-label>
          <input
            matInput
            #widthInput
            [formControl]="doorElevationFormGroup.doorFormGroups[0].width"
            [value]="doorElevationFormGroup.doorFormGroups[0].width.value"
            (ngModelChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].width, $event)"
            [ngModel]="doorElevationFormGroup.doorFormGroups[0].width.value"
            libApplyMeasure
            [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
          />
        </mat-form-field>
      </div>
      <div class="col grow">
        <mat-form-field>
          <mat-label>{{ 'FORMFIELDS.height' | translate }}</mat-label>
          <input
            matInput
            [formControl]="doorElevationFormGroup.height"
            [value]="doorElevationFormGroup.height.value"
            (ngModelChange)="updateForm(doorElevationFormGroup.height, $event)"
            [ngModel]="doorElevationFormGroup.height.value"
            libApplyMeasure
            [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
          />
        </mat-form-field>
      </div>
      <div class="col grow">
        <mat-form-field>
          <mat-label>{{ 'FORMFIELDS.undercut' | translate }}</mat-label>
          <input
            matInput
            [formControl]="doorElevationFormGroup.undercut"
            [value]="doorElevationFormGroup.undercut.value"
            [disabled]="doorElevationFormGroup.undercut.disabled"
            (ngModelChange)="updateForm(doorElevationFormGroup.undercut, $event)"
            [ngModel]="doorElevationFormGroup.undercut.value"
            libApplyMeasure
            [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row section-header form-header">
      <span>{{ 'doorSettings' | translate }}</span>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.series' | translate"
          [disabled]="doorElevationFormGroup.series.disabled"
          [value]="doorElevationFormGroup.series.value"
          [selectedItemValue]="doorElevationFormGroup.series.value"
          (selectionChange)="updateForm(doorElevationFormGroup.series, $event)"
          [optionsList]="doorElevationFormGroup.serieses"
          [required]="required(doorElevationFormGroup.series)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.type' | translate"
          [disabled]="doorElevationFormGroup.subType.disabled"
          [selectedItemValue]="doorElevationFormGroup.subType.value"
          (selectionChange)="updateForm(doorElevationFormGroup.subType, $event)"
          [optionsList]="doorElevationFormGroup.subTypes"
          [required]="required(doorElevationFormGroup.subType)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.gauge' | translate"
          [disabled]="doorElevationFormGroup.gauge.disabled"
          [selectedItemValue]="doorElevationFormGroup.gauge.value"
          (selectionChange)="updateForm(doorElevationFormGroup.gauge, $event)"
          [optionsList]="doorElevationFormGroup.gauges"
          [required]="required(doorElevationFormGroup.gauge)"
        >
        </lib-input-option>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.thickness' | translate"
          [disabled]="doorElevationFormGroup.thickness.disabled"
          [selectedItemValue]="doorElevationFormGroup.thickness.value"
          (selectionChange)="updateForm(doorElevationFormGroup.thickness, $event)"
          [optionsList]="doorElevationFormGroup.thicknesses"
          [required]="required(doorElevationFormGroup.thickness)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.handing' | translate"
          [disabled]="doorElevationFormGroup.doorFormGroups[0].handing.disabled"
          [selectedItemValue]="doorElevationFormGroup.doorFormGroups[0].handing.value"
          (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].handing, $event)"
          [optionsList]="doorElevationFormGroup.doorFormGroups[0].handings"
          [required]="required(doorElevationFormGroup.doorFormGroups[0].handing)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.fireRating' | translate"
          [disabled]="doorElevationFormGroup.fireRating.disabled"
          [selectedItemValue]="doorElevationFormGroup.fireRating.value"
          (selectionChange)="updateForm(doorElevationFormGroup.fireRating, $event)"
          [optionsList]="doorElevationFormGroup.fireRatings"
          [required]="required(doorElevationFormGroup.fireRating)"
        >
        </lib-input-option>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.additionalLabel' | translate"
          [disabled]="doorElevationFormGroup.additionalLabel.disabled"
          [selectedItemValue]="doorElevationFormGroup.additionalLabel.value"
          (selectionChange)="updateForm(doorElevationFormGroup.additionalLabel, $event)"
          [optionsList]="doorElevationFormGroup.additionalLabels"
          [required]="required(doorElevationFormGroup.additionalLabel)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [disabled]="doorElevationFormGroup.approval.disabled"
          [selectedItemValue]="doorElevationFormGroup.approval.value"
          (selectionChange)="updateForm(doorElevationFormGroup.approval, $event)"
          [optionsList]="doorElevationFormGroup.approvals"
          label="{{ 'FORMFIELDS.approval' | translate }}"
          [required]="required(doorElevationFormGroup.approval)"
        >
          <span class="lib-open-in-new-label" hint>
             <a
              href="https://www.republicdoor.com/en/products/doors/dl-series-hurricane-doors.html"
              target="__blank"
            >
              <span>{{ 'referenceTools' | translate }}</span
              >&nbsp;<i class="material-icons">open_in_new</i> </a
            >
          </span>
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.metalOrMylar' | translate"
          [disabled]="doorElevationFormGroup.metalMylar.disabled"
          [selectedItemValue]="doorElevationFormGroup.metalMylar.value"
          (selectionChange)="updateForm(doorElevationFormGroup.metalMylar, $event)"
          [optionsList]="doorElevationFormGroup.metalMylars"
          [required]="required(doorElevationFormGroup.metalMylar)"
        >
        </lib-input-option>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="('FORMFIELDS.material' | translate)"
          [disabled]="doorElevationFormGroup.material.disabled"
          [selectedItemValue]="doorElevationFormGroup.material.value"
          (selectionChange)="updateForm(doorElevationFormGroup.material, $event)"
          [optionsList]="doorElevationFormGroup.materials"
          [required]="required(doorElevationFormGroup.material)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="('FORMFIELDS.lockEdge' | translate)"
          [disabled]="doorElevationFormGroup.doorFormGroups[0].edge.disabled"
          [selectedItemValue]="doorElevationFormGroup.doorFormGroups[0].edge.value"
          (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].edge, $event)"
          [optionsList]="doorElevationFormGroup.edges"
          [required]="required(doorElevationFormGroup.doorFormGroups[0].edge)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.edgeType' | translate"
          [disabled]="doorElevationFormGroup.seamlessEdge.disabled"
          [selectedItemValue]="doorElevationFormGroup.seamlessEdge.value"
          (selectionChange)="updateForm(doorElevationFormGroup.seamlessEdge, $event)"
          [optionsList]="doorElevationFormGroup.seamlessEdges"
          [required]="required(doorElevationFormGroup.seamlessEdge)"
        >
        </lib-input-option>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="('FORMFIELDS.steelStiffen' | translate)"
          [disabled]="doorElevationFormGroup.steelStiffen.disabled"
          [selectedItemValue]="doorElevationFormGroup.steelStiffen.value"
          (selectionChange)="updateForm(doorElevationFormGroup.steelStiffen, $event)"
          [optionsList]="doorElevationFormGroup.steelStiffens"
          [required]="required(doorElevationFormGroup.steelStiffen)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="('FORMFIELDS.core' | translate)"
          [disabled]="doorElevationFormGroup.core.disabled"
          [selectedItemValue]="doorElevationFormGroup.core.value"
          (selectionChange)="updateForm(doorElevationFormGroup.core, $event)"
          [optionsList]="doorElevationFormGroup.cores"
          [required]="required(doorElevationFormGroup.core)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="('FORMFIELDS.style' | translate)"
          [disabled]="doorElevationFormGroup.doorFormGroups[0].style.disabled"
          [selectedItemValue]="doorElevationFormGroup.doorFormGroups[0].style.value"
          (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].style, $event)"
          [optionsList]="doorElevationFormGroup.doorFormGroups[0].styles"
          [required]="required(doorElevationFormGroup.doorFormGroups[0].style)"
        >
          <ng-template let-option="option" #optionTemplate>
            <div class="style">
              <img src="{{ environment.elevations.blobUrl + 'templates/' + option + '.svg' }}" /><span>{{
                option
              }}</span>
            </div>
          </ng-template>
        </lib-input-option>
      </div>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.topChannel' | translate"
          [disabled]="doorElevationFormGroup.topChannel.disabled"
          [selectedItemValue]="doorElevationFormGroup.topChannel.value"
          (selectionChange)="updateForm(doorElevationFormGroup.topChannel, $event)"
          [optionsList]="doorElevationFormGroup.topChannels"
          [required]="required(doorElevationFormGroup.topChannel)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.bottomChannel' | translate"
          [disabled]="doorElevationFormGroup.bottomChannel.disabled"
          [selectedItemValue]="doorElevationFormGroup.bottomChannel.value"
          (selectionChange)="updateForm(doorElevationFormGroup.bottomChannel, $event)"
          [optionsList]="doorElevationFormGroup.bottomChannels"
          [required]="required(doorElevationFormGroup.bottomChannel)"
        >
        </lib-input-option>
      </div>
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.finishPaint' | translate"
          [disabled]="doorElevationFormGroup.finishPaint.disabled"
          [selectedItemValue]="doorElevationFormGroup.finishPaint.value"
          (selectionChange)="updateForm(doorElevationFormGroup.finishPaint, $event)"
          [optionsList]="doorElevationFormGroup.finishPaints"
          [required]="required(doorElevationFormGroup.finishPaint)"
        >
        </lib-input-option>
      </div>
    </div>

    <div class="row section-header form-header" *ngIf="doorElevationFormGroup.doorFormGroups[0].hasLite()">
      <span>{{ 'FORMFIELDS.glassOptions' | translate }}</span>
    </div>
    <div class="row" *ngIf="doorElevationFormGroup.doorFormGroups[0].hasLite()">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.installation' | translate"
          [disabled]="doorElevationFormGroup.doorFormGroups[0].glassInstallation.disabled"
          [selectedItemValue]="doorElevationFormGroup.doorFormGroups[0].glassInstallation.value"
          (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].glassInstallation, $event)"
          [optionsList]="doorElevationFormGroup.doorFormGroups[0].glassInstallations"
          [required]="required(doorElevationFormGroup.doorFormGroups[0].glassInstallation)"
        >
        </lib-input-option>
      </div>
      <div class="col grow" *ngIf="!doorElevationFormGroup.doorFormGroups[0].isCutoutOnly(); else EmptyCol">
        <mat-form-field>
          <mat-label>{{ 'FORMFIELDS.glassThickness' | translate }}</mat-label>
          <input
            matInput
            (ngModelChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].glassThickness, $event)"
            [value]="doorElevationFormGroup.doorFormGroups[0].glassThickness.value"
            [disabled]="doorElevationFormGroup.doorFormGroups[0].glassThickness.disabled"
            [required]="required(doorElevationFormGroup.doorFormGroups[0].glassThickness)"
            libApplyMeasure
            [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
        /></mat-form-field>
      </div>
      <div
        class="col grow"
        *ngIf="
          doorElevationFormGroup.fireRating.value && !doorElevationFormGroup.doorFormGroups[0].isCutoutOnly();
          else EmptyCol
        "
      >
        <lib-input-option
          [label]="'FORMFIELDS.glassType' | translate"
          [disabled]="doorElevationFormGroup.doorFormGroups[0].glassType.disabled"
          [selectedItemValue]="doorElevationFormGroup.doorFormGroups[0].glassType.value"
          (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].glassType, $event)"
          [optionsList]="doorElevationFormGroup.doorFormGroups[0].glassTypes"
          [required]="required(doorElevationFormGroup.doorFormGroups[0].glassType)"
        >
        </lib-input-option>
      </div>
    </div>

    <div class="row section-header form-header" *ngIf="doorElevationFormGroup.doorFormGroups[0].hasLouver()">
      <span>{{ 'FORMFIELDS.louverOptions' | translate }}</span>
    </div>
    <div class="row" *ngIf="doorElevationFormGroup.doorFormGroups[0].hasLouver()">
      <div class="col grow">
        <lib-input-option
          [label]="'FORMFIELDS.installation' | translate"
          [disabled]="doorElevationFormGroup.doorFormGroups[0].louverInstallation.disabled"
          [selectedItemValue]="doorElevationFormGroup.doorFormGroups[0].louverInstallation.value"
          (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].louverInstallation, $event)"
          [optionsList]="doorElevationFormGroup.doorFormGroups[0].louverInstallations"
          [required]="required(doorElevationFormGroup.doorFormGroups[0].louverInstallation)"
        >
        </lib-input-option>
      </div>
      <div class="col grow"></div>
      <div class="col grow"></div>
    </div>
    <div class="row lib-radio-group">
      <mat-label>{{ ('QUESTIONS.isThisActiveSingle' | translate) + ' *' }}</mat-label>
      <mat-radio-group
        (change)="updateForm(doorElevationFormGroup.doorFormGroups[0].active, $event.value)"
        [value]="doorElevationFormGroup.doorFormGroups[0].active.value"
      >
        <mat-radio-button [value]="true" color="primary">{{ 'yes' | translate }}</mat-radio-button>
        <mat-radio-button [value]="false" color="primary">{{ 'no' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row">
      <div class="col grow" *ngIf="doorElevationFormGroup.isDutchStyle(0); else EmptyCol">
        <div class="astragal-container">
          <lib-input-option
            fieldClass="no-hint"
            [label]="'FORMFIELDS.astragal' | translate"
            [disabled]="
              doorElevationFormGroup.doorFormGroups[0].astragal.disabled || doorElevationFormGroup.wideInactive.value
            "
            [selectedItemValue]="doorElevationFormGroup.doorFormGroups[0].astragal.value"
            (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[0].astragal, $event)"
            [optionsList]="doorElevationFormGroup.doorFormGroups[0].astragals"
            [required]="required(doorElevationFormGroup.doorFormGroups[0].astragal)"
          ></lib-input-option>
          <mat-icon
            matSuffix
            matTooltip="Flat bar astragal is required for the following preps: Electric Strikes,
          Open Back Strikes, OLSMS Surface Mounted Strikes, 1-1/4'' FBC Flushbolts, or Pair of Exit Devices."
            svgIcon="info"
          ></mat-icon>
        </div>
      </div>
      <div class="col grow"></div>
      <div class="col grow"></div>
    </div>

    <div class="row section-header form-header">
      <span class="padding top s4">{{ 'doorPreps' | translate }}</span>
    </div>
    <div class="row">
      <div class="col grow">
        <lib-input-option
          fieldClass="no-hint"
          [label]="'FORMFIELDS.prepLocationPreference' | translate"
          [selectedItemValue]="doorElevationFormGroup.prepLocationPreference.value"
          (selectionChange)="updateForm(doorElevationFormGroup.prepLocationPreference, $event)"
          [optionsList]="doorElevationFormGroup.prepLocationPreferences"
        >
        </lib-input-option>
      </div>
      <div class="col grow"></div>
      <div class="col grow"></div>
    </div>

    <div class="row space-between">
      <button
        mat-stroked-button
        class="margin s3 bottom top"
        aria-label="{{ 'BUTTON.previous' | translate }}"
        (click)="configService.goBack()"
        [disabled]="!configService.isNew"
      >
        {{ 'BUTTON.previous' | translate }}
      </button>
      <div class="flex-row">
        <mat-checkbox class="padding s7 right" color="primary" [(ngModel)]="configService.saveAsPreference">{{
          ((configService.preferenceExists$ | async) ? 'updatePreference' : 'saveAsPreference') | translate
        }}</mat-checkbox>
        <button mat-stroked-button class="margin s3 all" (click)="configService.closeDialog()">Cancel</button>
        <button
          class="continue margin s3 all"
          mat-flat-button
          color="primary"
          (click)="continue()"
          [disabled]="doorElevationFormGroup.invalid || doorElevationFormGroup.doorFormGroups[0].invalid"
        >
          {{ (configService.isNew ? 'BUTTON.selectPreps' : 'BUTTON.finish') | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #EmptyCol>
  <div class="col grow"></div>
</ng-template>
