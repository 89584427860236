import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule, Type } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { AppInsightsModule } from '@overtur/app-insights'
import { IStorageService, STORAGE_SERVICE } from '../../interfaces/i-storage-service'
import { MaterialModule } from '../../material/material.module'
import { SEARCH_AND_FILTER_SERVICE, ISearchAndFilterService } from '../../types/search-and-filter-base'
import { ButtonComponent } from './column-type-components/button/button.component'
import { CurrencyComponent } from './column-type-components/currency/currency.component'
import { DateComponent } from './column-type-components/date/date.component'
import { DefaultComponent } from './column-type-components/default/default.component'
import { DropdownComponent } from './column-type-components/dropdown/dropdown.component'
import { IconComponent } from './column-type-components/icon/icon.component'
import { CrossReferenceInputComponent } from './column-type-components/input/crossReference/crossReference-input.component'
import { InputComponent } from './column-type-components/input/input.component'
import { MenuComponent } from './column-type-components/menu/menu.component'
import { ToggleComponent } from './column-type-components/toggle/toggle.component'
import { DynamicRowComponent } from './dynamic-row/dynamic-row.component'
import { SelectColumnsComponent } from './select-columns/select-columns.component'
import { TableComponent } from './table.component'

@NgModule({
  declarations: [
    ButtonComponent,
    CrossReferenceInputComponent,
    CurrencyComponent,
    DateComponent,
    DefaultComponent,
    DropdownComponent,
    IconComponent,
    InputComponent,
    MenuComponent,
    SelectColumnsComponent,
    TableComponent,
    ToggleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    DragDropModule,
    TranslateModule,
    AppInsightsModule,
    DynamicRowComponent
  ],
  providers: [],
  exports: [TableComponent]
})
export class TableModule {
  static forRoot(
    storageService: Type<IStorageService>,
    searchAndFilterService: Type<ISearchAndFilterService>
  ): ModuleWithProviders<TableModule> {
    return {
      ngModule: TableModule,
      providers: [
        { provide: STORAGE_SERVICE, useClass: storageService },
        {
          provide: SEARCH_AND_FILTER_SERVICE,
          useClass: searchAndFilterService
        }
      ]
    }
  }
}
