<div class="content">
  <div class="breadcrumbs">
    <oa-breadcrumbs *ngIf="showBreadCrumbs"></oa-breadcrumbs>
  </div>
  <div class="icons" *ngIf="(customerService.unauthorized$ | async) === false">
    <div class="customer">
      <form *ngIf="(customerService.isInternalUser$ | async) || (flagsService.flags$ | async)?.enableMultiBranch">
        <mat-form-field appearance="outline" floatLabel="always" class="company-control no-hint">
          <mat-label class="mat-label">{{ 'company' | translate }} </mat-label>
          <input
            matInput
            [readonly]="!interalUser || !(flagsService.flags$ | async)?.enableMultiBranch"
            [matAutocomplete]="auto"
            name="parentCompany"
            [value]="customerService.parentCompany?.customerName"
            ngModel
            (ngModelChange)="customerService.searchCompany$.next($event)"
            placeholder="{{ 'enter' | translate }}"
          />
          <div
            matSuffix
            class="company-search-suffix"
            *ngIf="
              (customerService.searchingCompanies$ | async)?.loading ||
              (customerService.searchingCompanies$ | async)?.error
            "
          >
            <mat-spinner
              *ngIf="(customerService.searchingCompanies$ | async)?.loading"
              mode="indeterminate"
              diameter="20"
              stokeWidth="10"
            ></mat-spinner>
            <mat-icon
              *ngIf="(customerService.searchingCompanies$ | async)?.error"
              [matTooltip]="'ERRORS.gettingCompanies' | translate"
            >
              error_outline
            </mat-icon>
          </div>
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="helperService.displayName"
            [panelWidth]="400"
          >
            <mat-option
              class="account-select"
              *ngFor="let parentCompany of customerService.parentCompanies"
              [value]="parentCompany"
              (click)="selectCompany(parentCompany)"
              ovtTrack="click"
              ovtCategory="MBA"
              ovtAction="Switch Company"
              [ovtProperties]="{ Location: 'Header' }"
            >
              <span class="mat-subheading-1"
                >{{ parentCompany.customerName | titlecase }} - {{ parentCompany.primaryCity | titlecase }}</span
              >
              <span class="mat-subheading-1" *ngIf="parentCompany.primaryState !== 'Not Available'"
                >, {{ parentCompany.primaryState | uppercase }}</span
              >
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          *ngIf="(flagsService.flags$ | async)?.enableMultiBranch"
          appearance="outline"
          floatLabel="always"
          class="company-control no-hint"
        >
          <mat-label class="mat-label">{{ 'branch' | translate }}</mat-label>
          <input
            #branchInput
            matInput
            [matAutocomplete]="autoBranch"
            name="branch"
            [value]="customerService.getBranchDisplayName(targetBranch)"
            libAutocomplete
            spellcheck="false"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoBranch="matAutocomplete"
            [displayWith]="customerService.getBranchDisplayName"
          >
            <mat-option
              *ngFor="
                let branch of customerService.customerBranches
                  | libAutoCompleteFilter: branchInput.autocompleteValue:'customerAccount'
              "
              (click)="customerService.selectBranch(branch.accountId)"
              ovtTrack="click"
              ovtCategory="MBA"
              ovtAction="Switch Branch"
              [ovtProperties]="{ Location: 'Header' }"
              [value]="branch"
              class="branch-option-select"
            >
              <span [ngClass]="{ 'branch-option': true, 'default-branch': customerService.isExternalUser$ | async }">
                <span>{{ customerService.getBranchDisplayName(branch) }}</span>

                <mat-icon
                  *ngIf="customerService.isExternalUser$ | async"
                  [ngClass]="{ selected: branch.accountId === customerService.defaultBranchOnLogin }"
                  (click)="$event.stopPropagation(); customerService.defaultBranchOnLogin = branch.accountId"
                >
                  {{ branch.accountId === customerService.defaultBranchOnLogin ? 'star' : 'star_border' }}
                </mat-icon>
              </span>
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix class="drop-down">arrow_drop_down</mat-icon>
        </mat-form-field>
      </form>
    </div>
    <button
      class="square oeo-button"
      [routerLink]="['/notifications']"
      [ngClass]="{ primary: new }"
      attr.aria-label="{{ 'notifications' | translate }}"
      *ngIf="flags?.notifications"
    >
      <span class="material-symbols-outlined"> notifications </span>
    </button>
    <button
      class="square oeo-button"
      matTooltip="{{ 'resources' | translate }}"
      attr.aria-label="{{ 'resources' | translate }}"
      [matMenuTriggerFor]="resources"
      [disabled]="(customerService.unauthorized$ | async) === true"
    >
      <mat-icon svgIcon="school"></mat-icon>
    </button>
  </div>
</div>

<mat-menu #resources="matMenu">
  <button mat-menu-item (click)="openHelpModel()">{{ 'help' | translate }}</button>
  <ng-container *ngFor="let resource of resourceLinks">
    <a
      mat-menu-item
      [href]="resource.link"
      ovtTrack
      ovtCategory="Resources"
      ovtAction="Click"
      [ovtLabel]="resource.text"
      [target]="'_blank'"
    >
      {{ resource.text | translate }}
    </a>
  </ng-container>
</mat-menu>
