import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable, debounceTime } from 'rxjs'
import { ActiveFilters, Filter, SearchAndFilters } from '..'
import { CustomerService } from '../../services/customer/customer.service'
import { SortingAndPaginationEvent } from '@oeo/common'

export abstract class BackendSearchAndFilterService {
  constructor(protected http: HttpClient, protected customerService: CustomerService) {}

  url: string
  columns: { [key: string]: string }[]
  #params = new BehaviorSubject<SortingAndPaginationEvent>(null)
  params$ = this.#params.asObservable()
  get params() {
    return this.#params.getValue()
  }
  set params(params: SortingAndPaginationEvent) {
    this.#params.next(params)
  }
  fullLists: Filter[] = []
  protected _filterLists: BehaviorSubject<Filter[]> = new BehaviorSubject([] as Filter[])
  filterLists$: Observable<Filter[]> = this._filterLists.asObservable()
  protected _searchAndFilters: BehaviorSubject<SearchAndFilters> = new BehaviorSubject({
    search: '',
    activeFilters: {}
  })
  currentFilters: ActiveFilters = {}
  searchAndFilters$: Observable<SearchAndFilters> = this._searchAndFilters.asObservable().pipe(debounceTime(500))

  getSearchAndFilters() {
    return this._searchAndFilters.getValue()
  }

  updateSearch(search: string): void {
    this._searchAndFilters.next({
      search,
      activeFilters: this._searchAndFilters.getValue().activeFilters
    })
  }

  clearSearchAndFilters() {
    this._searchAndFilters.next({
      search: '',
      activeFilters: {}
    })
  }

  updateFilters(activeFilters: ActiveFilters) {
    this.currentFilters = activeFilters
    this._searchAndFilters.next({
      activeFilters,
      search: this._searchAndFilters.getValue().search
    })
  }

  abstract generateURLParameters(searchAndFilters: SearchAndFilters): string
}
