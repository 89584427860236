<h2 mat-dialog-title>{{ 'TITLE.etoHelp' | translate }}</h2>
<mat-dialog-content>
  <p>
    {{ 'helpMessage' | translate }}
    <a
      href="mailto:{{
        accessEmail
      }}?subject=Access%20Request%20for%20Estimate%20to%20Order&body=Please%20grant%20me%20access%20to%20Estimate%20to%20Order"
    >
      {{ supportEmail }}
    </a>
  </p>
  <p>
    {{ 'technicalIssue' | translate }}
    <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">{{ 'ACTIONS.close' | translate }}</button>
</mat-dialog-actions>
