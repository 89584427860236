<div class="lib-prep-container">
  <div class="left">
    <lib-input-option
      [selectedItemValue]="code"
      (selectionChange)="code = $event"
      transformType="preps"
      [optionsList]="codes"
      [label]="title"
    >
      <span class="lib-open-in-new-label" *ngIf="linkToPrepCodes" hint>
        <a
          href="https://us.allegion.com/content/dam/allegion-us-2/portal/help/eto-training-resources/Frame%20Nomenclature.pdf"
          target="__blank"
        >
          <span>{{ 'prepCodeGuide' | translate }}</span
          >&nbsp;<i class="material-icons">open_in_new</i>
        </a>
      </span>
    </lib-input-option>
    <lib-input-option
      label="Location *"
      [selectedItemValue]="location"
      (selectionChange)="location = $event"
      [disabled]="!code"
      [optionsList]="locations"
      transformType="value"
    >
    </lib-input-option>
    <mat-form-field>
      <mat-label>Reference Point *</mat-label>
      <mat-select
        [value]="referencePoint"
        (selectionChange)="referencePoint = $event"
        [disabled]="!code || code.fixedLocation || location !== specialPrepLocation"
      >
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </div>
  <lib-frame-prep
    *ngIf="frameElevation"
    [frameElevation]="frameElevation"
    [subTypes]="subTypes"
    [prepLocationInputsFilled]="prepLocationInputsFilled"
  >
  </lib-frame-prep>
</div>
