<div class="header">
  <div class="row">
    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea
        matInput
        placeholder="This is the message that will display to the user when this hardware type is selected during hardware retrieval"
        (ngModelChange)="message.patchValue($event)"
        [rows]="4"
        [maxLength]="1000"
        [value]="message.value"
      >
      </textarea>
    </mat-form-field>
  </div>
</div>
<div class="categories">
  <div class="row">
    <h3>{{ 'categories' | translate }}</h3>
  </div>
  <div class="hardware-items">
    <mat-checkbox color="primary" [(ngModel)]="c.checked" *ngFor="let c of values">{{ c.name }}</mat-checkbox>
  </div>
</div>
<div class="footer padding s7 left right">
  <div class="spacer"></div>
  <div class="actions">
    <button class="mat-flat-button" color="primary" mat-flat-button (click)="save()">{{ 'save' | translate }}</button>
  </div>
</div>
