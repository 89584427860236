import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, skipWhile } from 'rxjs/operators';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IUserService, USERSERVICE_INJECTION_TOKEN } from '../interfaces/i-user-service';

@Injectable()
export class UserService implements CanActivate {
  private _isAdmin: boolean;
  public get isAdmin(): boolean {
    return this._isAdmin;
  }
  public set isAdmin(value: boolean) {
    this._isAdmin = value;
  }
  private get isAdmin$(): Observable<boolean> {
    return this.isAdmin == null
      ? this.auth0Service.authProfile$.pipe(
          skipWhile(v => v == null),
          first(),
          map(
            profile =>
              (this.isAdmin =
                !this.environment.production || profile['http://allegion.com/claims/roles'].includes('ElevationsAdmin'))
          )
        )
      : of(this.isAdmin);
  }

  constructor(
    @Inject(USERSERVICE_INJECTION_TOKEN) private auth0Service: IUserService,
    @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment
  ) {
    this.isAdmin$.subscribe();
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAdmin$;
  }
}
