import { Component, OnInit, OnDestroy } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { EstimatesDialogComponent } from '../../../../estimates/components/estimates-dialog/estimates-dialog.component'
import { EstimateType } from '../../../../models'
import { Features, CustomerType } from '../../../../models/features.model'
import { FlagsService } from '../../../../services/flags/flags.service'

@Component({
  selector: 'oa-create-new-dialog',
  templateUrl: './create-new-dialog.component.html',
  styleUrls: ['./create-new-dialog.component.scss']
})
export class CreateNewDialogComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject()
  estimateLabel!: string
  flags = this.flagsService.getFlags()
  estimateTypes = EstimateType

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateNewDialogComponent>,
    private flagsService: FlagsService
  ) {}

  ngOnInit() {
    this.estimateLabel = this.flags.isDirect ? 'TITLE.estimate/order' : 'project'
  }

  openNewEstimateDialog(estimateType: EstimateType, showHollowMetal: boolean) {
    this.dialogRef.close()
    this.dialog.open(EstimatesDialogComponent, {
      data: {
        action: 'create',
        showHollowMetal: showHollowMetal,
        estimateType: estimateType
      },
      width: '50vw',
      disableClose: true
    })
  }

  cancel() {
    this.dialogRef.close()
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
