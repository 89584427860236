

<div class="lib-prep-container">
  <form class="left" #PrepFieldsForm="ngForm">
    <mat-form-field>
      <mat-label>{{'FORMFIELDS.hingePrep'|translate}}</mat-label>
      <input
        matInput
        [ngModel]="getDisplayValue(code)"
        (ngModelChange)="setPrepCode($event)"
        [matAutocomplete]="auto"
        #hingePrepInput
        required
        [libValidValueValidator]="codes"
        validatorTransformFn="preps"
        name="hingePrepCode"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of codes|libAutoCompleteFilter:hingePrepInput.autocompleteValue:'preps'" [value]="getDisplayValue(option)">
          {{ getDisplayValue(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'FORMFIELDS.hingeQty' | translate }}</mat-label>
      <mat-select name="hingeQty" [(ngModel)]="prep.quantity" [disabled]="noHinge || isDutchStyle">
        <mat-option *ngFor="let c of quantityOptions" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="lib-radio-group" *ngIf="!isContinuousHinge && prep.quantity">
      <mat-label>{{ 'FORMFIELDS.isThisElectricHinge' | translate }}</mat-label>
      <mat-radio-group required name="isThisElectricHinge" [(ngModel)]="prep.isElectricHinge">
        <mat-radio-button [value]="'Yes'" color="primary">{{ 'yes' | translate }}</mat-radio-button>
        <mat-radio-button [value]="'No'" color="primary">{{ 'no' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field *ngIf="!isContinuousHinge && electricHingeSelected">
      <mat-label>{{'FORMFIELDS.conduit' | translate}}</mat-label>
      <mat-select name="conduitYesNo" required  [(ngModel)]="prep.conduit">
        <mat-option *ngFor="let c of conduitYesNo" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="!isContinuousHinge && electricHingeSelected">
      <mat-label>{{'FORMFIELDS.electricHingeLocation' | translate}}</mat-label>
      <input
        matInput
        #electricHingeLocationInput
        [matAutocomplete]="electricHingeLocationAuto"
        name="electricHingeLocation"
        [(ngModel)]="prep.electricHingeLocation"
        [libValidValueValidator]="electricHingeLocations"
        required
      />
      <mat-autocomplete #electricHingeLocationAuto="matAutocomplete">
        <mat-option *ngFor="let option of electricHingeLocations|libAutoCompleteFilter:electricHingeLocationInput.autocompleteValue" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field *ngIf="!isContinuousHinge && electricHingeSelected">
      <mat-label>{{'FORMFIELDS.locationWithinHinge' | translate}}</mat-label>
      <input
        matInput
        #locationWithinHingeInput
        [matAutocomplete]="locationWithinHingeAuto"
        name="locationWithinHinge"
        [(ngModel)]="prep.locationWithinHinge"
        [libValidValueValidator]="locationsWithinHinge"
        required
      />
      <mat-autocomplete #locationWithinHingeAuto="matAutocomplete">
        <mat-option *ngFor="let option of locationsWithinHinge|libAutoCompleteFilter:locationWithinHingeInput.autocompleteValue" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field *ngIf="!isContinuousHinge">
      <mat-label>{{'FORMFIELDS.location' | translate}}</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        #locationInput
        [matAutocomplete]="locationAuto"
        name="location"
        [(ngModel)]="prep.locationType"
        [libValidValueValidator]="locations"
        validatorTransformFn="value"
        required
      />
      <mat-autocomplete #locationAuto="matAutocomplete">
        <mat-option *ngFor="let option of locations|libAutoCompleteFilter:locationInput.autocompleteValue:'value'" [value]="option.value">
          {{ option.value }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'FORMFIELDS.referencePoint' | translate }}</mat-label>
      <mat-select
        name="referencePoint"
        [(ngModel)]="prep.referencePoint"
        [disabled]="noHinge || prep.locationType !== specialPrepLocation.value"
      >
        <mat-option *ngFor="let c of referencePoints" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="code !== specialPrepCode">
      <mat-label>{{ 'FORMFIELDS.backset' | translate }}</mat-label>
      <mat-select name="backset" [(ngModel)]="prep.backset" [disabled]="noHinge">
        <mat-option *ngFor="let c of backsets" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="door.doorElevation.isSteelcraft">
      <mat-label>{{ 'FORMFIELDS.highFrequency' | translate }}</mat-label>
      <mat-select name="highFrequency" [(ngModel)]="highFrequency" [disabled]="noHinge">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let c of highFrequencies" [value]="c">{{ c }}</mat-option>
      </mat-select>
    </mat-form-field>
    <lib-special-hardware [component]="this" [isSpecialPrep]="isSpecialPrep"></lib-special-hardware>
  </form>
  <lib-door-prep *ngIf="door" [door]="door" [prepLocationInputsFilled]="prepLocationInputsFilled">
    {{ isContinuousHinge ? ('MESSAGES.continuousHingeDoor' | translate) : '' }}
  </lib-door-prep>
</div>
