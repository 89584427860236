<div class="lib-prep-container">
  <div class="left">
    <mat-form-field>
      <mat-label>{{ name }}</mat-label>
      <mat-select [value]="code" (selectionChange)="code = $event">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let c of codes" [value]="c"
          >{{ c.code }} {{ !!c.description ? '(' + c.description + ')' : '' }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <lib-frame-prep
    *ngIf="frameElevation"
    [frameElevation]="frameElevation"
    [subTypes]="subTypes"
    [showMessage]="showMessage"
    [prepLocationInputsFilled]="prepLocationInputsFilled"
  ></lib-frame-prep>
</div>
