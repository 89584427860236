import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule, Type } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { StepperComponent } from './components/stepper/stepper.component'
import { InputSelectComponent } from './components/input-select/input-select.component'
import { AutoCompleteDirective } from './directives/autocomplete.directive'
import { DynamicRowDirective } from './directives/dynamic-row.directive'
import { IStorageService, STORAGE_SERVICE } from './interfaces/i-storage-service'
import { MaterialModule } from './material/material.module'
import { AutoCompleteFilterPipe } from './pipes/autocompleteFilter.pipe'
import { SEARCH_AND_FILTER_SERVICE, ISearchAndFilterService } from './types/search-and-filter-base'
import { ValidValueValidatorDirective } from './directives/validValue.validator'
import { TableModule } from './components/table/table.module'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AutoCompleteDirective,
    AutoCompleteFilterPipe,
    ValidValueValidatorDirective,
    DynamicRowDirective,
    TableModule,
    InputSelectComponent,
    StepperComponent
  ],
  exports: [
    InputSelectComponent,
    StepperComponent,
    MaterialModule,
    AutoCompleteDirective,
    AutoCompleteFilterPipe,
    ValidValueValidatorDirective,
    DynamicRowDirective,
    TableModule
  ]
})
export class OEOCommonModule {
  static forRoot(
    storageService: Type<IStorageService>,
    searchAndFilterService: Type<ISearchAndFilterService>
  ): ModuleWithProviders<OEOCommonModule> {
    return {
      ngModule: OEOCommonModule,
      providers: [
        { provide: STORAGE_SERVICE, useClass: storageService },
        {
          provide: SEARCH_AND_FILTER_SERVICE,
          useClass: searchAndFilterService
        }
      ]
    }
  }
}
