import { Injectable } from '@angular/core'
import createAuth0Client, { GetTokenSilentlyOptions, GetUserOptions, User } from '@auth0/auth0-spa-js'
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'
import { RoutingService } from './routing/routing.service'
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs'
import { tap, catchError, concatMap, shareReplay, take, map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { StorageService } from './local-storage/local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: { path: string; queryParams: {} }

  // Create an observable of Auth0 instance of client
  auth0Client$ = (
    from(
      createAuth0Client({
        audience: environment.authConfig.audience,
        domain: environment.authConfig.domain,
        client_id: environment.authConfig.clientID,
        redirect_uri: environment.authConfig.redirectUri,
        scope: environment.authConfig.scope
      })
    ) as Observable<Auth0Client>
  ).pipe(
    // eslint-disable-next-line rxjs/no-sharereplay
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(err))
  )
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap((res) => (this.loggedIn = res))
  )
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  )
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<User>(null)
  userProfile$ = this.userProfileSubject$.asObservable()
  get userProfile(): User {
    return this.userProfileSubject$.getValue()
  }
  // Create a local property for login status
  loggedIn: boolean = null

  constructor(private routingService: RoutingService, private storageService: StorageService) {
    const search = location.search.length > 0 ? decodeURIComponent(location.search.substring(1)) : ''
    let queryParams = {}
    if (search.length > 0) {
      queryParams = search.split('&').reduce((acc: Record<string, string>, part) => {
        const [key, value] = part.split('=')
        acc[key] = value
        return acc
      }, {})
    }
    this.url = { path: window.location.pathname, queryParams }

    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup()
    // Handle redirect from Auth0 login
    this.handleAuthCallback()
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?: GetUserOptions): Observable<User> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap((user) => this.userProfileSubject$.next(user))
    )
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
  getTokenSilently$(
    options?: GetTokenSilentlyOptions & {
      detailedResponse: true
    }
  ): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from<Promise<any>>(client.getTokenSilently(options)))
    )
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$()
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn)
      })
    )
    checkAuth$.subscribe()
  }

  login(redirectPath: string = '/landing') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: environment.authConfig.redirectUri,
        appState: { target: '/landing' }
      })
    })
  }

  sso(redirectPath: string = '/landing') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: environment.authConfig.redirectUri,
        appState: { target: redirectPath },
        prompt: 'none'
      })
    })
  }

  handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap((cbRes) => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/landing'
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([this.getUser$(), this.isAuthenticated$])
        })
      )
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        // Redirect to target route after callback processing
        this.routingService.navigate([targetRoute])
      })
    } else {
      this.isAuthenticated$.pipe(take(1)).subscribe((user) => {
        if (!user) {
          this.login()
        } else {
          this.routingService.navigate([this.url.path], {
            queryParams: this.url.queryParams,
            queryParamsHandling: 'merge'
          })
        }
      })
    }
  }

  logout() {
    //clear all the local storage settings
    this.storageService.clearAuthKeys()

    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: environment.authConfig.clientID,
        returnTo: environment.authConfig.logoutUrl
      })
    })
  }
}
