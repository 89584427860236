<h2 mat-dialog-title>
  {{ 'createQuestion' | translate }}
  <button type="button" mat-icon-button attr.aria-label="{{ 'ACTIONS.closeModal' | translate }}" (click)="cancel()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</h2>
<mat-dialog-content class="content" [ngClass]="{ adSystems: flags.adSystems, proExpress: flags.proExpress }">
  <div>
    <img src="/assets/img/illustrations/standard.svg" alt="" />
    <h3>{{ estimateLabel | translate }}</h3>
    <p>{{ 'DESCRIPTION.estimate/order' | translate }}</p>
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="openNewEstimateDialog(estimateTypes.Standard, true)"
      ovtTrack
      ovtCategory="Estimate"
      ovtAction="Create Estiamate"
      [ovtProperties]="{ Location: 'New Estimate Dialog', EstimateType: 'Standard' }"
    >
      {{ 'getStarted' | translate }}
    </button>
  </div>

  <div *ngIf="flags.adSystems">
    <img src="/assets/img/illustrations/ad_systems.svg" alt="{{ 'adSystems' | translate }}" />
    <h3>{{ 'adSystems' | translate }}</h3>
    <p>{{ 'DESCRIPTION.adSystems' | translate }}</p>
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="openNewEstimateDialog(estimateTypes.ADSystems, false)"
      ovtTrack
      ovtCategory="Estimate"
      ovtAction="Create Estimate"
      [ovtProperties]="{ Location: 'New Estimate Dialog', EstimateType: 'AD Systems' }"
    >
      {{ 'getStarted' | translate }}
    </button>
  </div>
</mat-dialog-content>
