import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../../core/interfaces/i-environment';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'lib-left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss'],
})
export class LeftBarComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  navButtons = [
    { name: 'TITLE.home', active: false, icon: 'home', router: './home', visible: () => true },
    {
      name: 'TITLE.drawingLibrary',
      active: false,
      icon: 'architecture',
      router: './drawingLibrary',
      visible: () => true,
    },
    {
      name: 'TITLE.manageHardware',
      active: false,
      icon: 'preps',
      router: './manageHardware',
      visible: () => this.userService.isAdmin,
    },
  ];
  collapsed = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(ENVIRONMENT_INJECTION_TOKEN) public environment: IEnvironment,
    public userService: UserService
  ) {
    this.router.events.pipe(takeUntil(this._destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navButtons.forEach(n => (n.active = n.router.split('/').last() === event.url.split('/').last()));
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  select(event: { name: string; active: boolean; icon: string; router: string }) {
    this.navButtons.forEach(button => {
      button.active = false;
    });
    event.active = true;
    this.router.navigate([event.router], { relativeTo: this.route });
  }

  toggleCollaped() {
    this.collapsed = !this.collapsed;
  }
}
