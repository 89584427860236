<div class="pair-settings-container">
  <div>
    <ng-container *ngIf="step === 0">
      <div class="door-settings-content" [ngClass]="{ preps: step > 2 }">
        <ng-container *ngTemplateOutlet="doorImages"></ng-container>
        <div class="door-general-settings">
          <span *ngIf="elevationContainsPreps" class="prepWarning">
            <mat-icon svgIcon="info"></mat-icon> {{ 'WARNINGS.yourElevationHasPreps' }}
          </span>
          <span>{{ 'INSTRUCTIONS.allRequiredFields' | translate }}</span>
          <div class="row section-header">
            <span>{{ 'unitsOfMeasurement' | translate }}</span>
          </div>
          <mat-radio-group
            (change)="updateForm(doorElevationFormGroup.unitOfMeasure, $event.value)"
            [value]="doorElevationFormGroup.unitOfMeasure.value"
            class="lib-radio-group padding bottom s4"
          >
            <mat-radio-button [value]="doorElevationFormGroup.unitOfMeasure.value">{{
              doorElevationFormGroup.unitOfMeasure.value | translate
            }}</mat-radio-button>
          </mat-radio-group>
          <div class="row section-header">
            <span>{{ 'nominalDimensions' | translate }}</span>
          </div>
          <span
            >{{ 'INSTRUCTIONS.decimalOrFraction' | translate }} {{ 'INSTRUCTIONS.leftRightDims' | translate }}
          </span>
          <div class="row padding top s7">
            <div class="col grow">
              <mat-form-field>
                <mat-label class="no-hint">{{ 'FORMFIELDS.openingWidth' | translate }}</mat-label>
                <input
                  matInput
                  [formControl]="doorElevationFormGroup.width"
                  [value]="doorElevationFormGroup.width.value"
                  (ngModelChange)="updateForm(doorElevationFormGroup.width, $event)"
                  [disabled]="doorElevationFormGroup.width.disabled"
                  libApplyMeasure
                  [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
                />
              </mat-form-field>
            </div>
            <div class="col grow">
              <mat-form-field>
                <mat-label class="no-hint">{{ 'FORMFIELDS.openingHeight' | translate }}</mat-label>
                <input
                  matInput
                  [formControl]="doorElevationFormGroup.height"
                  [value]="doorElevationFormGroup.height.value"
                  (ngModelChange)="updateForm(doorElevationFormGroup.height, $event)"
                  [disabled]="doorElevationFormGroup.height.disabled"
                  libApplyMeasure
                  [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
                />
              </mat-form-field>
            </div>
            <div class="col grow">
              <mat-form-field class="no-hint">
                <mat-label>{{ 'FORMFIELDS.undercut' | translate }}</mat-label>
                <input
                  matInput
                  [formControl]="doorElevationFormGroup.undercut"
                  [value]="doorElevationFormGroup.undercut.value"
                  [disabled]="doorElevationFormGroup.undercut.disabled"
                  (ngModelChange)="updateForm(doorElevationFormGroup.undercut, $event)"
                  libApplyMeasure
                  [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row section-header">
            <span>{{ 'doorSettings' | translate }}</span>
          </div>
          <div class="row">
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.series' | translate)"
                [optionsList]="doorElevationFormGroup.serieses"
                [selectedItemValue]="doorElevationFormGroup.series.value"
                [disabled]="doorElevationFormGroup.series.disabled"
                (selectionChange)="updateForm(doorElevationFormGroup.series, $event)"
                [required]="required(doorElevationFormGroup.series)"
              >
              </lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.type' | translate)"
                [disabled]="doorElevationFormGroup.subType.disabled"
                [selectedItemValue]="doorElevationFormGroup.subType.value"
                (selectionChange)="updateForm(doorElevationFormGroup.subType, $event)"
                [optionsList]="doorElevationFormGroup.subTypes"
                [required]="required(doorElevationFormGroup.subType)"
              ></lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.thickness' | translate)"
                [disabled]="doorElevationFormGroup.thickness.disabled"
                [selectedItemValue]="doorElevationFormGroup.thickness.value"
                (selectionChange)="updateForm(doorElevationFormGroup.thickness, $event)"
                [optionsList]="doorElevationFormGroup.thicknesses"
                [required]="required(doorElevationFormGroup.thickness)"
              >
              </lib-input-option>
            </div>
          </div>
          <div class="row">
            <div class="col grow">
              <lib-input-option
                [label]="'FORMFIELDS.fireRating' | translate"
                [disabled]="doorElevationFormGroup.fireRating.disabled"
                [selectedItemValue]="doorElevationFormGroup.fireRating.value"
                (selectionChange)="updateForm(doorElevationFormGroup.fireRating, $event)"
                [optionsList]="doorElevationFormGroup.fireRatings"
                [required]="required(doorElevationFormGroup.fireRating)"
              >
              </lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="'FORMFIELDS.additionalLabel' | translate"
                [disabled]="doorElevationFormGroup.additionalLabel.disabled"
                [selectedItemValue]="doorElevationFormGroup.additionalLabel.value"
                (selectionChange)="updateForm(doorElevationFormGroup.additionalLabel, $event)"
                [optionsList]="doorElevationFormGroup.additionalLabels"
                [required]="required(doorElevationFormGroup.additionalLabel)"
              >
              </lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [disabled]="doorElevationFormGroup.approval.disabled"
                [selectedItemValue]="doorElevationFormGroup.approval.value"
                (selectionChange)="updateForm(doorElevationFormGroup.approval, $event)"
                [optionsList]="doorElevationFormGroup.approvals"
                label="{{ 'FORMFIELDS.approval' | translate }}"
                [required]="required(doorElevationFormGroup.approval)"
              >
                <span class="lib-open-in-new-label" hint>
                  <a
                    href="https://www.republicdoor.com/en/products/doors/dl-series-hurricane-doors.html"
                    target="__blank"
                  >
                    <span>{{ 'referenceTools' | translate }}</span
                    >&nbsp;<i class="material-icons">open_in_new</i>
                  </a>
                </span>
              </lib-input-option>
            </div>
          </div>
          <div class="row">
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.metalOrMylar' | translate)"
                [disabled]="doorElevationFormGroup.metalMylar.disabled"
                [selectedItemValue]="doorElevationFormGroup.metalMylar.value"
                (selectionChange)="updateForm(doorElevationFormGroup.metalMylar, $event)"
                [optionsList]="doorElevationFormGroup.metalMylars"
                [required]="required(doorElevationFormGroup.metalMylar)"
              ></lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.gauge' | translate)"
                [disabled]="doorElevationFormGroup.gauge.disabled"
                [selectedItemValue]="doorElevationFormGroup.gauge.value"
                (selectionChange)="updateForm(doorElevationFormGroup.gauge, $event)"
                [optionsList]="doorElevationFormGroup.gauges"
                [required]="required(doorElevationFormGroup.gauge)"
              >
              </lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.material' | translate)"
                [disabled]="doorElevationFormGroup.material.disabled"
                [selectedItemValue]="doorElevationFormGroup.material.value"
                (selectionChange)="updateForm(doorElevationFormGroup.material, $event)"
                [optionsList]="doorElevationFormGroup.materials"
                [required]="required(doorElevationFormGroup.material)"
              >
              </lib-input-option>
            </div>
          </div>
          <div class="row">
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.edgeType' | translate)"
                [disabled]="doorElevationFormGroup.seamlessEdge.disabled"
                [selectedItemValue]="doorElevationFormGroup.seamlessEdge.value"
                (selectionChange)="updateForm(doorElevationFormGroup.seamlessEdge, $event)"
                [optionsList]="doorElevationFormGroup.seamlessEdges"
                [required]="required(doorElevationFormGroup.seamlessEdge)"
              >
              </lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.steelStiffen' | translate)"
                [disabled]="doorElevationFormGroup.steelStiffen.disabled"
                [selectedItemValue]="doorElevationFormGroup.steelStiffen.value"
                (selectionChange)="updateForm(doorElevationFormGroup.steelStiffen, $event)"
                [optionsList]="doorElevationFormGroup.steelStiffens"
                [required]="required(doorElevationFormGroup.steelStiffen)"
              >
              </lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.core' | translate)"
                [disabled]="doorElevationFormGroup.core.disabled"
                [selectedItemValue]="doorElevationFormGroup.core.value"
                (selectionChange)="updateForm(doorElevationFormGroup.core, $event)"
                [optionsList]="doorElevationFormGroup.cores"
                [required]="required(doorElevationFormGroup.core)"
              >
              </lib-input-option>
            </div>
          </div>
          <div class="row">
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.topChannel' | translate)"
                [disabled]="doorElevationFormGroup.topChannel.disabled"
                [selectedItemValue]="doorElevationFormGroup.topChannel.value"
                (selectionChange)="updateForm(doorElevationFormGroup.topChannel, $event)"
                [optionsList]="doorElevationFormGroup.topChannels"
                [required]="required(doorElevationFormGroup.topChannel)"
              >
              </lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.bottomChannel' | translate)"
                [disabled]="doorElevationFormGroup.bottomChannel.disabled"
                [selectedItemValue]="doorElevationFormGroup.bottomChannel.value"
                (selectionChange)="updateForm(doorElevationFormGroup.bottomChannel, $event)"
                [optionsList]="doorElevationFormGroup.bottomChannels"
                [required]="required(doorElevationFormGroup.bottomChannel)"
              >
              </lib-input-option>
            </div>
            <div class="col grow">
              <lib-input-option
                [label]="('FORMFIELDS.finishPaint' | translate)"
                [disabled]="doorElevationFormGroup.finishPaint.disabled"
                [selectedItemValue]="doorElevationFormGroup.finishPaint.value"
                (selectionChange)="updateForm(doorElevationFormGroup.finishPaint, $event)"
                [optionsList]="doorElevationFormGroup.finishPaints"
                [required]="required(doorElevationFormGroup.finishPaint)"
              >
              </lib-input-option>
            </div>
          </div>
          <div class="row" *ngIf="doorElevationFormGroup.showDoubleEgress">
            <div class="lib-radio-group">
              <mat-label>{{ 'FORMFIELDS.doubleEgress' | translate }}</mat-label>
              <mat-radio-group
                [value]="doorElevationFormGroup.doubleEgress.value"
                (change)="updateForm(doorElevationFormGroup.doubleEgress, $event.value)"
                color="primary"
              >
                <mat-radio-button [value]="true">{{ 'yes' | translate }}</mat-radio-button>
                <mat-radio-button [value]="false">{{ 'no' | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row section-header">
            <span>{{ 'doorPreps' | translate }}</span>
          </div>
          <div class="row">
            <div class="col grow">
              <lib-input-option
                [label]="'FORMFIELDS.prepLocationPreference' | translate"
                [selectedItemValue]="doorElevationFormGroup.prepLocationPreference.value"
                (selectionChange)="updateForm(doorElevationFormGroup.prepLocationPreference, $event)"
                [optionsList]="doorElevationFormGroup.prepLocationPreferences"
              >
              </lib-input-option>
            </div>
            <div class="col grow"></div>
            <div class="col grow"></div>
          </div>
          <span class="spacer"></span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="step === 1">
      <ng-container *ngTemplateOutlet="doorTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="step === 2">
      <ng-container *ngTemplateOutlet="doorTemplate"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #doorImages>
  <div class="column-center">
    <div class="images column-margin">
      <div class="pair-doors">
        <img
          [ngClass]="[step === 1 ? 'selected' : '', doorElevationFormGroup.doorFormGroups[0].handing.value ?? '']"
          src="{{
            environment.elevations.blobUrl +
              'templates/' +
              (step > 0 ? doorElevationFormGroup?.doorFormGroups[0]?.style?.value ?? 'F' : 'F') +
              '.svg'
          }}"
        />
        <img
          [ngClass]="[step === 2 ? 'selected' : '', doorElevationFormGroup.doorFormGroups[1].handing.value ?? '']"
          [class]="doorElevationFormGroup?.doorFormGroups[1]?.style?.value"
          src="{{
            environment.elevations.blobUrl +
              'templates/' +
              (step > 0 ? doorElevationFormGroup?.doorFormGroups[1]?.style?.value ?? 'F' : 'F') +
              '.svg'
          }}"
        />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #doorTemplate>
  <div class="door-settings-content">
    <ng-container *ngTemplateOutlet="doorImages"></ng-container>
    <div class="form">
      <div class="row section-header">
        <span>{{ 'nominalDimensions' | translate }}</span>
      </div>
      <span>{{ 'INSTRUCTIONS.decimalOrFraction' | translate }}</span>
      <div class="row padding top s7">
        <div class="col grow">
          <mat-form-field>
            <mat-label>{{ 'FORMFIELDS.width' | translate }}</mat-label>
            <input
              matInput
              [formControl]="doorElevationFormGroup.doorFormGroups[step - 1].width"
              [value]="doorElevationFormGroup.doorFormGroups[step - 1].width.value"
              (ngModelChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].width, $event)"
              [readonly]="step === 2 && doorElevationFormGroup.doorFormGroups[step - 1].width.invalid"
              libApplyMeasure
              [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
            />
          </mat-form-field>
        </div>
        <div class="col grow">
          <mat-form-field>
            <mat-label>{{ 'FORMFIELDS.height' | translate }}</mat-label>
            <input
              matInput
              [formControl]="doorElevationFormGroup.doorFormGroups[step - 1].height"
              [value]="doorElevationFormGroup.height.value"
              (ngModelChange)="updateForm(doorElevationFormGroup.height, $event)"
              [readonly]="true"
            />
          </mat-form-field>
        </div>
        <div class="col grow"></div>
      </div>
      <div class="row section-header">
        <span>{{ 'doorSettings' | translate }}</span>
      </div>
      <div class="row">
        <div class="col grow">
          <lib-input-option
            [label]="'FORMFIELDS.style' | translate"
            [disabled]="doorElevationFormGroup.doorFormGroups[step - 1].style.disabled"
            [selectedItemValue]="doorElevationFormGroup.doorFormGroups[step - 1].style.value"
            (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].style, $event)"
            [optionsList]="doorElevationFormGroup.doorFormGroups[step - 1].styles"
            [required]="required(doorElevationFormGroup.doorFormGroups[step - 1].style)"
          >
            <ng-template let-option="option" #optionTemplate>
              <div class="style">
                <img src="{{ environment.elevations.blobUrl + 'templates/' + option + '.svg' }}" /><span>{{
                  option
                }}</span>
              </div>
            </ng-template>
          </lib-input-option>
        </div>
        <div class="col grow">
          <lib-input-option
            [label]="'FORMFIELDS.handing' | translate"
            [disabled]="doorElevationFormGroup.doubleEgress.value && step === 2"
            [selectedItemValue]="doorElevationFormGroup.doorFormGroups[step - 1].handing.value"
            (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].handing, $event)"
            [optionsList]="doorElevationFormGroup.doorFormGroups[step - 1].handings"
            [required]="required(doorElevationFormGroup.doorFormGroups[step - 1].handing)"
          >
          </lib-input-option>
        </div>
        <div class="col grow">
          <lib-input-option
            [label]="'FORMFIELDS.lockEdge' | translate"
            [disabled]="doorElevationFormGroup.doorFormGroups[step - 1].edge.disabled"
            [selectedItemValue]="doorElevationFormGroup.doorFormGroups[step - 1].edge.value"
            (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].edge, $event)"
            [optionsList]="doorElevationFormGroup.edges"
            [required]="required(doorElevationFormGroup.doorFormGroups[step - 1].edge)"
          >
          </lib-input-option>
        </div>
      </div>
      <div class="row section-header form-header" *ngIf="doorElevationFormGroup.doorFormGroups[step - 1].hasLite()">
        <span>{{ 'glassOptions' | translate }}</span>
      </div>

      <div class="row" *ngIf="doorElevationFormGroup.doorFormGroups[step - 1].hasLite(); else EmptyCol">
        <ng-container>
          <div class="col grow">
            <lib-input-option
              [label]="'FORMFIELDS.installation' | translate"
              [disabled]="doorElevationFormGroup.doorFormGroups[step - 1].glassInstallation.disabled"
              [selectedItemValue]="doorElevationFormGroup.doorFormGroups[step - 1].glassInstallation.value"
              (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].glassInstallation, $event)"
              [optionsList]="doorElevationFormGroup.doorFormGroups[step - 1].glassInstallations"
              [required]="required(doorElevationFormGroup.doorFormGroups[step - 1].glassInstallation)"
            >
            </lib-input-option>
          </div>
          <div class="col grow" *ngIf="!doorElevationFormGroup.doorFormGroups[step - 1].isCutoutOnly(); else EmptyCol">
            <mat-form-field>
              <mat-label>{{ 'FORMFIELDS.glassThickness' | translate }}</mat-label>
              <input
                matInput
                [value]="doorElevationFormGroup.doorFormGroups[step - 1].glassThickness.value"
                (ngModelChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].glassThickness, $event)"
                [disabled]="doorElevationFormGroup.doorFormGroups[step - 1].glassThickness.disabled"
                libApplyMeasure
                [unitOfMeasure]="doorElevationFormGroup.unitOfMeasure.value"
              />
            </mat-form-field>
          </div>
        </ng-container>

        <div
          class="col grow"
          *ngIf="
            doorElevationFormGroup.fireRating.value && !doorElevationFormGroup.doorFormGroups[step - 1].isCutoutOnly();
            else EmptyCol
          "
        >
          <lib-input-option
            [label]="'FORMFIELDS.glassType' | translate"
            [disabled]="doorElevationFormGroup.doorFormGroups[step - 1].glassType.disabled"
            [selectedItemValue]="doorElevationFormGroup.doorFormGroups[step - 1].glassType.value"
            (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].glassType, $event)"
            [optionsList]="doorElevationFormGroup.doorFormGroups[step - 1].glassTypes"
            [required]="required(doorElevationFormGroup.doorFormGroups[step - 1].glassType)"
          >
          </lib-input-option>
        </div>
      </div>

      <div class="row section-header form-header" *ngIf="doorElevationFormGroup.doorFormGroups[step - 1].hasLouver()">
        <span>{{ 'louverOptions' | translate }}</span>
      </div>
      <div class="row" *ngIf="doorElevationFormGroup.doorFormGroups[step - 1].hasLouver()">
        <div class="col grow">
          <lib-input-option
            [label]="('FORMFIELDS.installation' | translate)"
            [disabled]="doorElevationFormGroup.doorFormGroups[step - 1].louverInstallation.disabled"
            [selectedItemValue]="doorElevationFormGroup.doorFormGroups[step - 1].louverInstallation.value"
            (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].louverInstallation, $event)"
            [optionsList]="doorElevationFormGroup.doorFormGroups[step - 1].louverInstallations"
            [required]="required(doorElevationFormGroup.doorFormGroups[step - 1].louverInstallation)"
          >
          </lib-input-option>
        </div>
        <div class="col grow"></div>
        <div class="col grow"></div>
      </div>
      <div class="row">
        <div class="lib-radio-group">
          <mat-label>{{ 'QUESTIONS.isThisActive' | translate }}</mat-label>
          <mat-radio-group
            (change)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].active, $event.value)"
            [value]="doorElevationFormGroup.doorFormGroups[step - 1].active.value"
          >
            <mat-radio-button color="primary" [value]="true">{{ 'yes' | translate }}</mat-radio-button>
            <mat-radio-button color="primary" [value]="false">{{ 'no' | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div
        class="row"
        *ngIf="!doorElevationFormGroup.isDPSeries || !doorElevationFormGroup.doorFormGroups[step - 1].active.value"
      >
        <div class="col grow padding bottom s3">
          <div class="row">
            <div class="col grow">
              <lib-input-option
                fieldClass="no-hint"
                [label]="'FORMFIELDS.astragal' | translate"
                [disabled]="
                  doorElevationFormGroup.doorFormGroups[step - 1].astragal.disabled ||
                  doorElevationFormGroup.wideInactive.value
                "
                [selectedItemValue]="doorElevationFormGroup.doorFormGroups[step - 1].astragal.value"
                (selectionChange)="updateForm(doorElevationFormGroup.doorFormGroups[step - 1].astragal, $event)"
                [optionsList]="doorElevationFormGroup.doorFormGroups[step - 1].astragals"
                [required]="required(doorElevationFormGroup.doorFormGroups[step - 1].astragal)"
              ></lib-input-option>
            </div>
            <div class="col" style="flex-grow: 0; margin: auto">
              <mat-icon
                matSuffix
                matTooltip="Flat bar astragal is required for the following preps: Electric Strikes,
              Open Back Strikes, OLSMS Surface Mounted Strikes, 1-1/4'' FBC Flushbolts, or Pair of Exit Devices."
                svgIcon="info"
              ></mat-icon>
            </div>
          </div>
        </div>
        <div class="col grow"></div>
        <div class="col grow"></div>
      </div>
      <div class="row"></div>
    </div>
  </div>
</ng-template>
<div class="space-between row" *ngIf="step < 3">
  <button mat-stroked-button (click)="previous()" [disabled]="!configService.isNew && step === 0">
    {{ 'BUTTON.previous' | translate }}
  </button>
  <div class="flex-row">
    <mat-checkbox color="primary" class="padding s7 right" [(ngModel)]="configService.saveAsPreference">
      {{ ((configService.preferenceExists$ | async) ? 'updatePreference' : 'saveAsPreference') | translate }}
    </mat-checkbox>
    <button class="margin s6 left" mat-stroked-button (click)="configService.closeDialog()">{{ 'BUTTON.cancel' | translate }}</button>
    <button
      class="continue margin s6 left"
      color="primary"
      mat-flat-button
      (click)="continue()"
      [disabled]="!canContinue()"
    >
      {{ (step === configService.steps.length - 1 ? 'BUTTON.finish' : 'BUTTON.continue') | translate }}
    </button>
  </div>
</div>

<ng-template #EmptyCol>
  <div class="col grow"></div>
</ng-template>
