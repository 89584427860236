<input
  *ngIf="column && row"
  [(ngModel)]="data"
  [ngModelOptions]="{ standalone: true }"
  (ngModelChange)="handleChange(data, $event)"
  (click)="stopPropagation($event)"
  [disabled]="(column?.validators?.disabled ?? false) || (row && row['marks_disabled'] ?? false)"
  [attr.minlength]="column?.validators?.minlength ?? 0"
  [attr.maxlength]="column?.validators?.maxlength"
  [max]="column?.validators?.max"
  [min]="column?.validators?.min"
  [pattern]="column?.validators?.pattern ?? ''"
  [type]="column?.validators?.type"
  [required]="column?.validators?.required ?? false"
  [step]="column?.validators?.step || 1"
  [ngClass]="{
    duplicate:
      (row?.duplicate && column?.validators?.uniq) ||
      (column?.validators?.divisible && row?.isQuantityDivisible === false),
    'oeo-input': true
  }"
  [class]="classes"
/>

<mat-hint *ngIf="warningMessages.length > 0">
  {{ warningMessages[0] | translate }} {{ product.orderMaximumQuantity }}
  <mat-icon
    class="info"
    matTooltip="This order exceeds the allocated limit for Fast Track Shipping. Excess items will be moved to standard purchase"
    >info</mat-icon
  >
</mat-hint>

<mat-error *ngIf="row?.duplicate && column?.validators?.uniq">{{ 'VALIDATION.unique' | translate }}</mat-error>
<mat-error *ngIf="column?.validators?.divisible && row?.isQuantityDivisible === false"
  >{{ 'VALIDATION.divisible' | translate }}{{ row?.quantityMultiple }}
</mat-error>
