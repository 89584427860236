import { HelperService } from './services/helper/helper.service'
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, OnDestroy, HostListener, SkipSelf } from '@angular/core'
import { AuthService } from './services/auth.service'
import { MatDialog } from '@angular/material/dialog'
import { HelpDialogComponent } from './core/components/help-dialog/help-dialog.component'
import { Router, NavigationEnd } from '@angular/router'
import { environment } from '../environments/environment'
import { CustomerService } from './services/customer/customer.service'
import { skipWhile, switchMap, take, takeUntil } from 'rxjs/operators'
import { Customer } from './models/customer.model'
import { Subject, Observable, EMPTY } from 'rxjs'
import { Features } from './models/features.model'
import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { IndexedDBService } from './services/indexedDB/indexed-db.service'
import { AppInsightsService } from '@overtur/app-insights'
import { User } from '@auth0/auth0-spa-js'
import { FlagsService } from './services/flags/flags.service'
import { StorageService } from './services/local-storage/local-storage.service'

type ITelemetryItem = Parameters<Parameters<AppInsightsService['appInsights']['addTelemetryInitializer']>[0]>[0]

@Component({
  selector: 'oa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<boolean> = new Subject()
  flags: Features
  profile: User
  path: boolean
  routes: Array<string>
  type: string
  pageLength: number
  customer$: Observable<Customer> = this.customerService.customer$
  bannerNotifications$ = this.helperService.bannerNotifications$
  showSideNav: boolean
  lang = this.storageService.getItem('i18n') ? this.storageService.getItem('i18n') : 'en'
  multiBranchEnabled = environment.featureFlags.enableMultiBranch
  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    this.helperService.calcContentHeight()
  }

  constructor(
    private dialog: MatDialog,
    public router: Router,
    private _auth: AuthService,
    private customerService: CustomerService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private indexedDB: IndexedDBService,
    public helperService: HelperService,
    @SkipSelf() public appInsightsService: AppInsightsService,
    public flagsService: FlagsService,
    public storageService: StorageService
  ) {
    this.indexedDB.init()
    this.registerIconsToRegistry()
  }

  ngOnInit() {
    // If there is a route saved in local storage, set this route
    if (!this.routes) {
      this.routes = this.routes = this.storageService.getItem('route')
    }
    // If there are no routes saved in local storage, initialize an empty array
    // If we initialize first, we will overwrite the route saved in local storage
    if (!this.routes) {
      this.routes = new Array()
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url) {
          this.showSideNav = !event.url.includes('elevations')
          this.routes.push(event.url)
          this.storageService.setItem('route', this.routes)
        }
      }
    })

    this.pageLength = this.storageService.getItem('pageLength')
    if (!this.pageLength) {
      this.pageLength = 15
      this.storageService.setItem('pageLength', this.pageLength)
    }
    this._auth
      .getUser$()
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((user: User) => {
          if (!user) {
            return EMPTY
          }
          this.initializeAppInsights(user)
          return this.customerService.authProfile$
        })
      )
      .subscribe((value) => {
        if (!value) {
          return
        }
        const type = this.customerService.getUserType(value)
        this.profile = value
        const handleIe = environment.featureFlags.ieNotification
        this.helperService.initializeBanners(type === 'internal', handleIe)
      })
  }

  openHelpModel() {
    this.dialog.open(HelpDialogComponent)
  }

  /**
   * Set up User Id in the app insights service
   * Have to extract the uid from the string returned from Auth0
   *
   * [Reference to Docs](https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext)
   */
  initializeAppInsights(user: User) {
    const uid = encodeURI(user.sub)
    this.appInsightsService.setUserId(uid, '', true)
    const telemetryInitializer = (item: ITelemetryItem) => {
      return item.baseType !== 'RemoteDependencyData'
    }
    this.flagsService.flags$
      .pipe(
        skipWhile((flags) => !flags),
        take(1)
      )
      .subscribe((flags) => {
        this.appInsightsService.appInsights.addTelemetryInitializer(telemetryInitializer)
        this.appInsightsService.setDefaultCustomProperties({ isADSEstimator: flags.adsEstimator })
      })
  }

  logout() {
    this.storageService.clearAuthKeys()
    this._auth.logout()
  }

  registerIconsToRegistry() {
    const icons = [
      'actions_menu',
      'add',
      'architect',
      'archive',
      'arrow_back',
      'arrow_down',
      'arrow_forward',
      'arrow_up',
      'autorenew',
      'blueprint',
      'box',
      'cancel',
      'check-circle',
      'check',
      'close',
      'configure',
      'cross-reference',
      'door',
      'download',
      'duplicate',
      'edit',
      'elevation',
      'estimates',
      'excel',
      'export',
      'filter',
      'hardware-sets',
      'key',
      'language',
      'legal',
      'library_add',
      'lock',
      'logout',
      'money',
      'notification',
      'oeo',
      'orders',
      'pdf',
      'pe',
      'quotes',
      'republic-pack',
      'resend',
      'school',
      'service-center',
      'settings',
      'share',
      'sliders',
      'tools',
      'trash',
      'upload',
      'user',
      'view_pdf',
      'view',
      'warning',
      'add_notes',
      'save'
    ]

    icons.forEach((icon) => {
      if (!icon) {
        return
      }
      this.iconRegistry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/${icon}.svg`))
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
