import { Component, Input, OnInit, Type, ViewChild } from '@angular/core'
import { DynamicRowDirective } from '../../../directives/dynamic-row.directive'
import { IDynamicRowComponent } from '../../../interfaces'

@Component({
  selector: 'lib-dynamic-row',
  templateUrl: './dynamic-row.component.html',
  styleUrls: ['./dynamic-row.component.scss'],
  standalone: true
})
export class DynamicRowComponent implements OnInit {
  @ViewChild(DynamicRowDirective, { static: true }) dynamicRowHost!: DynamicRowDirective

  @Input() component!: Type<any>
  @Input() data: any

  ngOnInit(): void {
    this.loadComponent()
  }

  loadComponent() {
    const viewContainerRef = this.dynamicRowHost.viewContainerRef
    viewContainerRef.clear()
    const componentRef = viewContainerRef.createComponent<IDynamicRowComponent>(this.component)
    componentRef.instance.data = this.data
  }
}
