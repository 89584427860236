<lib-dialog
  [header]="'TITLE.doorConfiguration' | translate"
  [closeable]="false"
  libMoveable
  [(top)]="top"
  [(right)]="right"
  [zIndex]="5"
  moveableElementName="mat-card-header"
>
  <span class="section-header">{{ 'doorsAndCutouts' | translate }}</span>
  <div class="row padding s4 top">
    <div class="col grow padding right">
      <mat-form-field class="no-hint">
        <mat-label>{{ 'door' | translate }}</mat-label>
        <mat-select [(ngModel)]="doorElevationService.currentDoorIndex">
          <mat-option *ngFor="let value of doorElevationService.elevation?.doors; let i = index" [value]="i"
            >{{ 'door' | translate }} {{ i + 1 }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col grow padding left">
      <mat-form-field class="no-hint">
        <mat-label>{{ 'FORMFIELDS.liteLouverEmbossment' | translate }}</mat-label>
        <mat-select [(ngModel)]="cutoutIndex">
          <mat-option [value]="null" *ngIf="cutouts.length === 0">--</mat-option>
          <mat-option *ngFor="let value of cutouts; let i = index" [value]="i">{{ cutouts[i].name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="doorElevationService.elevation.subType !== subTypes.EMB">
    <span class="section-header">{{ 'kit' | translate }}</span>
    <div class="row">
      <div class="col grow">
        <mat-form-field class="muntin no-hint">
          <mat-select [disabled]="!showKit" [(ngModel)]="kit">
            <mat-option [value]="null">--</mat-option>
            <mat-option *ngFor="let k of kits" [value]="k">{{ k }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <span class="section-header">{{ 'orderSize' | translate }}</span>
  <div class="row padding s6 top">
    <div class="col grow padding right">
      <mat-radio-group [disabled]="true" [value]="cutout?.orderSize">
        <mat-radio-button *ngFor="let value of orderSizes" [value]="value" color="primary">{{
          value
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <span class="section-header">{{ 'measurements' | translate }}</span>
  <div class="measurements">
    <div class="col grow">
      <mat-label class="padding s4 bottom">{{ 'FORMFIELDS.horizontal' | translate }}</mat-label>
      <mat-radio-group
        [disabled]="!cutout"
        [value]="cutout?.horizontalDimensionMode"
        (change)="cutout.horizontalDimensionMode = $event.value"
      >
        <mat-radio-button *ngFor="let value of horizontalModes" [value]="value" color="primary">{{
          value
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col grow">
      <mat-label class="padding s4 bottom">{{ 'FORMFIELDS.vertical' | translate }}</mat-label>
      <mat-radio-group
        [disabled]="!cutout"
        [value]="cutout?.verticalDimensionMode"
        (change)="cutout.verticalDimensionMode = $event.value"
      >
        <mat-radio-button *ngFor="let value of verticalModes" [value]="value" color="primary">{{
          value
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="row padding s6 top" *ngIf="!cutout?.sizes.any()">
    <div class="col grow padding right">
      <mat-form-field>
        <mat-label>{{ 'width' | translate }}</mat-label>
        <input
          #width
          matInput
          libApplyMeasure
          [disabled]="!cutout?.sizeEditable"
          [ngModel]="cutout?.width.toDimension('door', doorElevationService.elevation.unitOfMeasure)"
          (keyup.enter)="updateValue('width', width.value)"
          (focus)="updateValue('width', width.value)"
          [unitOfMeasure]="doorElevationService.elevation.unitOfMeasure"
        />
      </mat-form-field>
    </div>
    <div class="col grow padding left">
      <mat-form-field>
        <mat-label>{{ 'height' | translate }}</mat-label>
        <input
          #height
          matInput
          libApplyMeasure
          [disabled]="!cutout?.sizeEditable"
          [ngModel]="cutout?.height.toDimension('door', doorElevationService.elevation.unitOfMeasure)"
          (keyup.enter)="updateValue('height', height.value)"
          (focus)="updateValue('height', height.value)"
          [unitOfMeasure]="doorElevationService.elevation.unitOfMeasure"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="cutout?.sizes.any()">
    <div class="col grow padding right">
      <mat-form-field>
        <mat-label>{{ 'FORMFIELDS.size' | translate }}</mat-label>
        <mat-select [ngModel]="cutout.size" (ngModelChange)="cutout.setSize($event)">
          <mat-option [value]="null">--</mat-option>
          <mat-option *ngFor="let value of cutout.sizes" [value]="value"
            >{{ value.width }} x {{ value.height }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col grow padding right">
      <mat-form-field>
        <mat-label>{{
          'distFrom' + (cutout?.horizontalDimensionMode ? cutout?.horizontalDimensionMode : '') | translate
        }}</mat-label>
        <input
          #horizontalDimension
          matInput
          libApplyMeasure
          [disabled]="!cutout?.positionEditable"
          [ngModel]="cutout?.horizontalDimension.toDimension('door', doorElevationService.elevation.unitOfMeasure)"
          (keyup.enter)="updateValue('horizontalDimension', horizontalDimension.value)"
          (focus)="updateValue('horizontalDimension', horizontalDimension.value)"
        />
      </mat-form-field>
    </div>
    <div class="col grow padding left">
      <mat-form-field>
        <mat-label>{{
          'distFrom' + (cutout?.verticalDimensionMode === 'Top' ? 'Top' : 'Bottom') | translate
        }}</mat-label>
        <input
          #verticalDimension
          matInput
          libApplyMeasure
          [disabled]="!cutout?.positionEditable"
          [ngModel]="cutout?.verticalDimension.toDimension('door', doorElevationService.elevation.unitOfMeasure)"
          (keyup.enter)="updateValue('verticalDimension', verticalDimension.value)"
          (focus)="updateValue('verticalDimension', verticalDimension.value)"
          (blur)="updateValue('verticalDimension', verticalDimension.value)"
          [errorStateMatcher]="cutout?.validation.errorStateMatcher"
        />
        <mat-error *ngIf="cutout?.validation.errors">{{
          cutout.validation.errors['verticalDimension']?.message
            | translate: { dimension: cutout.validation.errors['verticalDimension']?.dimension }
        }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="doorElevationService.currentDoor?.doorType.cutouts.length">
    <div class="col grow padding right">
      <mat-slide-toggle
        [checked]="doorElevationService.currentDoor.viewDimensionArrows"
        (change)="doorElevationService.currentDoor.viewDimensionArrows = $event.checked"
        color="primary"
        >{{ 'viewDimensions' | translate }}</mat-slide-toggle
      >
    </div>
    <div class="col grow padding left">
      <button
        class="margin mat-flat-button"
        mat-flat-button
        aria-label="{{ 'BUTTON.center' | translate }}"
        color="primary"
        (click)="center()"
        [disabled]="!cutout?.positionEditable"
      >
        {{ 'BUTTON.center' | translate }}
      </button>
    </div>
  </div>
  <span class="section-header">{{ 'muntinBars' | translate }}</span>
  <div class="row">
    <div class="col grow">
      <mat-form-field class="muntin no-hint">
        <mat-select [disabled]="cutout?.type !== 'Lite'" [(ngModel)]="muntinBar">
          <mat-option [value]="null">--</mat-option>
          <mat-option *ngFor="let muntinBar of muntinBars" [value]="muntinBar">{{ muntinBar.value }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</lib-dialog>
