import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable, AsyncSubject } from 'rxjs'
import { FlagsService } from '../../../services/flags/flags.service'
import { Features } from '../../../models/features.model'

@Injectable({
  providedIn: 'root'
})
export class FlagsResolver implements Resolve<Observable<Features>> {
  flags: Features
  constructor(private flagService: FlagsService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<Features> {
    const flags$: AsyncSubject<Features> = new AsyncSubject()
    this.flagService.flags$.subscribe((flags) => {
      if (flags) {
        flags$.next(flags)
        flags$.complete()
      }
    })
    return flags$
  }
}
