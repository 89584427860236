import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { clone, cloneDeep, orderBy } from 'lodash'
import { BehaviorSubject, EMPTY, firstValueFrom, forkJoin, from, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, filter, first, map, mergeMap, switchMap, take, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { Estimate } from '../../models/estimate.model'
import { ExperlogixSession } from '../../models/experlogixSession.model'
import { PurchaseOrderService } from './../purchase-order/purchase-order.service'

import {
  ADSEstimateErrors,
  DoorMarking,
  EstimateHardwareSet,
  EstimateProduct,
  EstimateProductAttachment,
  EstimateProductDoorMarking,
  EstimateProductUpdate,
  EstimateType,
  FlattenedHardwareSet,
  FlattenedProduct,
  IEstimatesResult,
  PurchaseOrder,
  QuantityToOrderHardwareSetDoorMarking,
  QuantityToOrderProductDoorMarking,
  RouteIds,
  SearchAndFilters
} from '../../models'

import { isRecentlyAddedItem, SortingAndPaginationEvent } from '@oeo/common'
import { ShareEmail } from '../../estimates/components/share-dialog/share-dialog.component'
import { BackendSearchAndFilterService } from '../../models/abstracts/BackendSearchAndFilterService'
import { CustomerService } from '../customer/customer.service'
import { HelperService } from '../helper/helper.service'
import { IndexedDBService } from '../indexedDB/indexed-db.service'
import { StorageService } from '../local-storage/local-storage.service'
import { RoutingService } from './../routing/routing.service'

export interface OrderedProducts {
  productId: number
  quantity: number
}

type EstimateProductLineNumberUpdate = { estimateProductId: number; lineNumber: number }

@Injectable({
  providedIn: 'root'
})
export class EstimatesService extends BackendSearchAndFilterService {
  url = environment.onlineOrderingApiUrl + 'Estimates'

  _estimate: BehaviorSubject<Estimate> = new BehaviorSubject(null as unknown as Estimate)
  estimate$: Observable<Estimate> = this._estimate.asObservable()
  estimate() {
    return this._estimate.getValue()
  }

  #estimatesResult: BehaviorSubject<IEstimatesResult> = new BehaviorSubject(null)
  estimatesResult$: Observable<IEstimatesResult> = this.#estimatesResult.asObservable().pipe(filter((res) => !!res))
  get estimatesResult() {
    return this.#estimatesResult.getValue()
  }
  estimateId!: number

  private _loaded = new BehaviorSubject(false)
  loaded$: Observable<boolean> = this._loaded.asObservable()
  getLoadedState(): boolean {
    return this._loaded.getValue()
  }

  private _loading = new BehaviorSubject(false)
  loading$: Observable<boolean> = this._loading.asObservable()
  getLoadingState(): boolean {
    return this._loading.getValue()
  }

  private _adsErrors = new BehaviorSubject<ADSEstimateErrors>(new ADSEstimateErrors())
  adsErrors$ = this._adsErrors.asObservable()
  get adsErrors() {
    return this._adsErrors.value
  }
  constructor(
    private indexedDb: IndexedDBService,
    private helperService: HelperService,
    private routingService: RoutingService,
    private poService: PurchaseOrderService,
    private storageService: StorageService,
    customerService: CustomerService,
    http: HttpClient
  ) {
    super(http, customerService)
    this.params = {
      tableName: 'estimates',
      skip: 0,
      pageSize: this.storageService.containsItem('pageLength') ? this.storageService.getItem('pageLength') : 15,
      property: 'lastModified',
      direction: 'desc'
    }
    this.columns = [
      { key: 'ModifiedBy.Name', title: 'TABLE_HEADERS.modifiedBy' },
      { key: 'EstimateType.Name', title: 'TABLE_HEADERS.estimateType' }
    ]
    this.estimate$.pipe(mergeMap((estimate) => this.checkForADSErrors(estimate))).subscribe({})

    this.routingService.ids$
      .pipe(
        distinctUntilChanged(),
        mergeMap((ids: RouteIds): Observable<Estimate> => {
          if (!!ids.estimateId && Number(this.estimateId) !== Number(ids.estimateId)) {
            this.estimateId = ids.estimateId
            return this.getCurrentEstimate(ids.estimateId)
          }
          return this.estimate$
        })
      )
      .subscribe()
  }

  getFilteredEstimates() {
    this._loading.next(true)
    return this.searchAndFilters$.pipe(
      switchMap((searchAndFilters) => this.getEstimates(this.params, searchAndFilters)),
      tap(() => this._loading.next(false))
    )
  }

  updateLoadingState(state: boolean): void {
    this._loading.next(state)
  }

  getCurrentEstimate(estimateId: number): Observable<Estimate> {
    this._loading.next(true)
    this._loaded.next(false)
    this.estimateId = Number(estimateId)
    return this.getEstimate(estimateId).pipe(
      tap(() => {
        this._loaded.next(true)
        this._loading.next(false)
      }),
      take(1)
    )
  }

  isADSEstimate(estimate?: Estimate) {
    if (estimate) return estimate.estimateTypeId === EstimateType.ADSystems
    return this.estimate()?.estimateTypeId === EstimateType.ADSystems
  }

  getPreviousQuantity(estimateId: number): Observable<[OrderedProducts]> {
    const url = `${this.url}/${estimateId}/OrderedProducts`
    return this.http.get<[OrderedProducts]>(url).pipe()
  }

  /**
   * Gets estimates for the current user
   * @returns @type {Observable<Estimate[]>}
   */
  public getEstimates(
    sortAndPagination: SortingAndPaginationEvent = this.params,
    searchAndFilters: SearchAndFilters = this.getSearchAndFilters()
  ): Observable<IEstimatesResult> {
    this.params.pageSize = sortAndPagination.pageSize
    this.params.skip = sortAndPagination.skip
    if (sortAndPagination.property && sortAndPagination.direction) {
      this.params.property = sortAndPagination.property.charAt(0).toUpperCase() + sortAndPagination.property.slice(1)
      this.params.direction = sortAndPagination.direction
    } else {
      this.params.property = 'LastModified'
      this.params.direction = 'desc'
    }
    this._loading.next(true)
    const url = this.url + this.generateURLParameters(searchAndFilters)
    return this.http.get<IEstimatesResult>(url).pipe(
      tap((res): Observable<IEstimatesResult> => {
        let estimates: Estimate[] = this.configureDataForView(res.estimates)
        estimates = estimates.filter(
          ({ estimateTypeId }) => estimateTypeId === EstimateType.Standard || estimateTypeId === EstimateType.ADSystems
        )
        const estimateResult: IEstimatesResult = { count: res.count, estimates }
        estimates = orderBy(estimates, ['created'], ['desc'])
        this.#estimatesResult.next(estimateResult)
        this._loading.next(false)
        return of(estimateResult)
      })
    )
  }

  /**
   * Configures data for view - sets the quote number for the estimate
   * @param estimates
   * @returns data for view
   */
  public configureDataForView(estimates: Estimate[]): Estimate[] {
    return estimates.map((estimate) => {
      let _estimate: Estimate = new Estimate()
      _estimate = Object.assign(_estimate, estimate)
      _estimate.quoteNumber = estimate?.quote?.quoteNumber ?? null
      return _estimate
    })
  }

  /**
   * Gets a single estimate
   * @param id
   * @returns Observable<estimate> - the estimate as an observable
   */
  public getEstimate(id: number): Observable<Estimate> {
    //  make sure we don't flash old data
    this._estimate.next(null)
    return this.getCleanEstimate(id).pipe(first())
  }

  public getCleanEstimate(id?: number) {
    const _id = id || this.estimateId
    this._estimate.next(null)
    if (!_id) {
      return EMPTY
    }
    const url = this.url + `/${_id}`
    return this.http.get(url, { headers: this.helperService.setLanguageHeaders() }).pipe(
      tap((res: Estimate) => {
        if (!res || res.id === this.estimateId) {
          this._estimate.next(res)
        }
      })
    )
  }

  public createEstimate(estimate: Estimate): Observable<Estimate> {
    const url = this.url
    return this.http.post<Estimate>(url, estimate).pipe(take(1))
  }

  public updateEstimate(estimate: Estimate): Observable<Estimate> {
    const url = this.url + `/${estimate.id}`
    return this.http.put(url, estimate).pipe(
      mergeMap((updatedEstimate: Estimate) => {
        if (updatedEstimate?.quote?.quoteNumber !== this.estimate()?.quote?.quoteNumber) {
          this.helperService.showQuoteNotificationChange(updatedEstimate.quote)
        }
        if (updatedEstimate.id === this.estimateId) {
          this._estimate.next(updatedEstimate)
        }
        return this.estimate$
      }),
      first()
    )
  }

  public copyEstimate(estimate: Estimate): Observable<number> {
    const payload = {
      name: estimate.name,
      quote: estimate.quote ? estimate.quote : null
    }
    const copyUrl = this.url + `/${estimate.id}/Copy`
    return this.http.post<number>(copyUrl, payload)
  }

  // TODO: Confirm Return type of this API Call
  public shareEstimate(
    estimateId: number,
    formValues: {
      emailList: ShareEmail[]
      emailAddress: string
      comments: string
    },
    estimateTypeId: number
  ): Observable<{}> {
    const payload = {
      emails: formValues.emailList.map((a) => a.email),
      comments: formValues.comments,
      isADSEstimate: estimateTypeId === EstimateType.ADSystems
    }
    return this.http.post<{}>(this.url + `/${estimateId}/share`, payload, {
      headers: this.helperService.setLanguageHeaders()
    })
  }

  public deleteEstimate(id: number): Observable<Estimate> {
    const url = this.url + `/${id}`
    return this.http.delete<Estimate>(url)
  }

  calculateGrandTotal(products: FlattenedProduct[], hardwareSets: FlattenedHardwareSet[]): number {
    let _total = 0
    if (products && products.length) {
      products.forEach((prod) => {
        if (Number(prod.extNetPrice)) {
          _total += prod.extNetPrice
        }
      })
    }
    if (hardwareSets && hardwareSets.length) {
      hardwareSets.forEach((set) => {
        if (Number(set.extNetPrice)) {
          _total += set.extNetPrice
        }
      })
    }
    return _total
  }

  public saveHardwareSetsToEstimate(
    id: number,
    hwSetObjArray: { hardwareSetId: number; quantity: number }[]
  ): Observable<Estimate> {
    const url = this.url + '/' + id + '/EstimateHardwareSets'
    this.http
      .post(url, hwSetObjArray)
      .pipe(
        tap((res: Estimate) => {
          if (res.id === this.estimateId) {
            this._estimate.next(res)
          }
          return this.estimate$
        }),
        take(1)
      )
      .subscribe()
    return this.estimate$
  }

  public saveProductsToEstimate(
    id: number,
    productObjArray: {
      productId: number
      quantity: number
    }[]
  ): Observable<Estimate> {
    const url = this.url + `/${id}/EstimateProducts`
    return this.http.post(url, productObjArray).pipe(
      first(),
      mergeMap((res: Estimate) => {
        if (res.id === this.estimateId) {
          this._estimate.next(res)
        }
        return this.estimate$
      })
    )
  }

  updateProductQuantity(estimateId: number, product: FlattenedProduct, newVal: number): Observable<Estimate> {
    product.isQuantityDivisible = product.quantityMultiple ? newVal % product.quantityMultiple === 0 : null
    if (product.quantity === newVal) {
      return EMPTY
    }
    if (isNaN(Number(newVal)) || newVal < 1) {
      this.helperService.openAlert({ title: 'ERRORS.invalidQty', state: 'error' })
      return EMPTY
    }
    // Check if the value is above the allowed max, epi returns an error if above 999999
    else if (Number(newVal) > 999999) {
      this.helperService.openAlert({ title: 'ERRORS.aboveMaxQuantity', state: 'error' })
      return EMPTY
    }
    if (product.isQuantityDivisible === false) {
      return EMPTY
    }
    const productUpdate: EstimateProductUpdate = {
      productId: product.productId,
      estimateProductId: product.id,
      lineNumber: product.lineNumber,
      quantity: Number(newVal),
      midnote: product.midnote
    }

    return this.updateEstimateProduct(estimateId, productUpdate)
  }

  handleHardwareSetValueUpdate(estimateId: number, hwSet: FlattenedHardwareSet, newVal: number): Observable<Estimate> {
    if (isNaN(newVal) || newVal < 1) {
      this.helperService.openAlert({ title: 'ERRORS.invalidQty', state: 'error' })
      return EMPTY
    }
    hwSet.quantity = Number(newVal)
    return this.updateEstimateHwSetQuantity(estimateId, hwSet)
  }

  public updateEstimateProduct(estimateId: number, productUpdate: EstimateProductUpdate): Observable<Estimate> {
    this._loading.next(true)
    const url = this.url + `/${estimateId}/EstimateProducts/${productUpdate.estimateProductId}`

    return this.http.put(url, productUpdate).pipe(
      // Get the updated price based on quantity
      tap((estimate) => {
        this.helperService.openAlert({ title: 'SUCCESSES.productQtyUpdate', state: 'success' })
        this._estimate.next(estimate)
        this._loading.next(false)
      }),
      catchError((err: Error) => {
        this.getCleanEstimate()
        return of(err)
      })
    )
  }

  public updateEstimateProductLines(
    estimate: Estimate,
    productToUpdate: FlattenedProduct,
    updatedLineNumber: number
  ): Observable<Estimate> {
    if (!updatedLineNumber || updatedLineNumber < 1 || updatedLineNumber > estimate.products.length) {
      return of(estimate)
    }

    const estimateProductLineNumberUpdates = this.getEstimateProductLineNumberUpdates(
      productToUpdate.id,
      updatedLineNumber,
      cloneDeep(estimate.products)
    )

    const updatedEstimate = clone(estimate)
    updatedEstimate.products = updatedEstimate.products.map((product) => {
      const updatedProduct = estimateProductLineNumberUpdates.find((prod) => prod.estimateProductId === product.id)
      if (updatedProduct) {
        product.lineNumber = updatedProduct.lineNumber
      }
      return product
    })
    /* Temporary UI Update */
    this._estimate.next(updatedEstimate)
    this._loading.next(true)
    const url = this.url + `/${estimate.id}/EstimateProducts`
    return this.http.put<Estimate>(url, estimateProductLineNumberUpdates).pipe(
      tap((res) => {
        this._estimate.next(res)
        this._loading.next(false)
      }),
      catchError((err: Error) => {
        this.getCleanEstimate()
        return of(err)
      }),
      tap(() => this._loading.next(false))
    )
  }

  getEstimateProductLineNumberUpdates(
    productId: number,
    lineNumber: number,
    products: EstimateProduct[]
  ): Array<EstimateProductLineNumberUpdate> {
    const ogList = cloneDeep(products)
    const productIndex = products.findIndex((product) => product.id === productId)
    const updatedProduct = { ...products.splice(productIndex, 1).first(), lineNumber }
    products.splice(lineNumber - 1, 0, updatedProduct)

    return products
      .map((p, i) => ({ ...p, lineNumber: i + 1 }))
      .reduce<Array<EstimateProductLineNumberUpdate>>((toUpdate, curr) => {
        if (ogList.find((p) => p.id === curr.id).lineNumber !== curr.lineNumber) {
          toUpdate.push({
            estimateProductId: curr.id,
            lineNumber: curr.lineNumber
          })
        }
        return toUpdate
      }, [])
  }

  public updateEstimateHwSetQuantity(estimateId: number, hwSet: FlattenedHardwareSet): Observable<Estimate> {
    this._loading.next(true)
    const updatedHwSetObj = {
      estimateHwSetId: hwSet.id,
      hardwareSetId: hwSet.hardwareSetId,
      quantity: Number(hwSet.quantity)
    }
    const url = this.url + `/${estimateId}/EstimateHardwareSets/${hwSet.id}`

    return this.http.put(url, updatedHwSetObj).pipe(
      first(),
      tap((estiamte: Estimate) => {
        this.helperService.openAlert({ title: 'SUCCESSES.hwsQtyUpdate', state: 'success' })
        this._estimate.next(estiamte)
        this._loading.next(false)
      }),
      catchError((err: Error) => {
        this.getCleanEstimate()
        return of(err)
      })
    )
  }

  public deleteProductFromEstimate(estimate: Estimate, product: FlattenedProduct): void {
    const url = this.url + `/${estimate.id}/EstimateProducts/${product.id}`
    this.http
      .delete(url)
      .pipe(
        take(1),
        mergeMap((res) => this.http.get(this.url + `/${estimate.id}`)),
        mergeMap((res: Estimate) => {
          if (res.id === this.estimateId) {
            this._estimate.next(res)
          }
          return of(res)
        })
      )
      .subscribe()
  }

  public deleteHardwareSetFromEstimate(estimate: Estimate, hwSet: FlattenedHardwareSet) {
    const url = this.url + `/${estimate.id}/EstimateHardwareSets/${hwSet.id}`
    return this.http.delete(url).pipe(
      take(1),
      mergeMap((res) => this.http.get(this.url + `/${estimate.id}`)),
      mergeMap((res: Estimate) => {
        if (res.id === this.estimateId) {
          this._estimate.next(res)
        }
        return of(res)
      })
    )
  }

  public goToConfigurator(
    estimateId: number,
    estimateProductId?: number | string,
    redirectUrl?: string
  ): Observable<ExperlogixSession | string> {
    let url = environment.onlineOrderingApiUrl + 'Estimates/' + estimateId
    if (estimateProductId) {
      url += '/EstimateProducts/' + estimateProductId
    }
    url += '/Configure'
    if (redirectUrl) {
      url += '?redirectUrl=' + redirectUrl
    }

    return this.http.get(url, { responseType: 'text' }).pipe(
      map((res) => {
        const session = <ExperlogixSession | string>res
        if (typeof session === 'string') {
          return session
        }

        if (!session.RedirectUrl) {
          session.RedirectUrl = redirectUrl
        }
        return session
      })
    )
  }

  getPurchaseOrderForEstimate(id: number): Observable<PurchaseOrder[]> {
    const poInfo$ = forkJoin({
      estimate: this.indexedDb.getCheckoutInfo(id),
      allProducts: this.indexedDb.getAllProductInfo(),
      allHardwareSets: this.indexedDb.getAllHardwareSetInfo(),
      keyedGroups: this.indexedDb.getKeyedGroups(id)
    })

    return from(poInfo$).pipe(
      switchMap((poInfo) => {
        const url = this.url + `/${id}/PurchaseOrder`
        let productDoorMarkings: QuantityToOrderProductDoorMarking[] = []
        let hardwareSetDoorMarkings: QuantityToOrderHardwareSetDoorMarking[] = []

        if (poInfo.estimate.isBeginPhasedOrderClicked) {
          const keyedEstimateProductIds: number[] = []
          const keyedEstimateHardwareSetIds: number[] = []
          //get all products and hws from the estimate's keyed groups
          poInfo.keyedGroups?.forEach((kg) => {
            kg.estimateProductsKeyed?.forEach((keyedProduct) => {
              if (!keyedEstimateProductIds.includes(keyedProduct.estimateProductId)) {
                keyedEstimateProductIds.push(keyedProduct.estimateProductId)
              }
            })
            kg.estimateHardwareSetsKeyed?.forEach((keyedHws) => {
              if (!keyedEstimateHardwareSetIds.includes(keyedHws.estimateHardwareSetId)) {
                keyedEstimateHardwareSetIds.push(keyedHws.estimateHardwareSetId)
              }
            })
          })

          poInfo.allProducts.forEach((product) => {
            if (product.quantityToOrderDoorMarks) {
              productDoorMarkings = productDoorMarkings.concat(product.quantityToOrderDoorMarks)
            } else {
              //for phased ordering makeup data for keyed Group products && products without doormarks
              const doorMarking = new QuantityToOrderProductDoorMarking()
              doorMarking.estimateProductId = product.id
              doorMarking.isKeyed = keyedEstimateProductIds.includes(product.id)
              doorMarking.quantityToOrder = product.quantityToOrder
              productDoorMarkings.push(doorMarking)
            }
          })

          poInfo.allHardwareSets.forEach((hws) => {
            if (hws.quantityToOrderDoorMarks) {
              hardwareSetDoorMarkings = hardwareSetDoorMarkings.concat(hws.quantityToOrderDoorMarks)
            } else {
              //for phased ordering makeup data for keyed Group HWS && HWS without doormarks
              const doorMarking = new QuantityToOrderHardwareSetDoorMarking()
              doorMarking.estimateHardwareSetId = hws.id
              doorMarking.isKeyed = keyedEstimateHardwareSetIds.includes(hws.id)
              doorMarking.quantityToOrder = hws.quantityToOrder
              hardwareSetDoorMarkings.push(doorMarking)
            }
          })
        }
        const payload = {
          useProExpress: poInfo.estimate.shipProExpress || false,
          useFastTrack: poInfo.estimate.shipFastTrack || false,
          rck: poInfo.estimate.residentialConstructionKeyed || false,
          includeDoorLabel: poInfo.estimate.includeDoorLabel || false,
          applyKeying: poInfo.estimate.keyed || false,
          isPhasedOrder: poInfo.estimate.isBeginPhasedOrderClicked || false,
          productDoorMarkings: productDoorMarkings,
          hardwareSetDoorMarkings: hardwareSetDoorMarkings
        }
        return this.http.post(url, payload) as Observable<PurchaseOrder[]>
      })
    )
  }

  getEstimateDocument(id: number, mimeType: string): Observable<any> {
    const url = this.url + `/${id}`
    let headers = this.helperService.setLanguageHeaders()
    headers = headers.set('Accept', mimeType)
    return <Observable<Estimate>>this.http.get(url, { headers: headers, responseType: 'blob' })
  }

  getHollowMatalDrawingsDocument(id: number, documentType: string): Observable<any> {
    const url = this.url + `/${id}` + '/hollowMetalDrawings'
    const contentType = `application/${documentType}`
    let headers = this.helperService.setLanguageHeaders()
    headers = headers.set('Accept', contentType)
    return <Observable<Estimate>>this.http.get(url, { headers: headers, responseType: 'blob' })
  }

  getCutSheets(id: number, type: 'zip' | 'pdf') {
    const url = this.url + `/${id}/Cutsheets`
    let headers = new HttpHeaders()
    headers = headers.set('Accept', `application/${type}`)
    return this.http.get(url, { headers: headers, responseType: 'blob' })
  }

  getConfigurationReport(estimateId: number, id: number, type: 'pdf') {
    const url = this.url + `/${estimateId}/EstimateProducts/${id}/ConfigurationReport`
    let headers = this.helperService.setLanguageHeaders()
    headers = headers.set('Accept', `application/${type}`)
    return this.http.get(url, {
      headers: headers,
      responseType: 'blob',
      observe: 'response'
    })
  }

  getEstimateSliFile(id: number, type: string): Observable<any> {
    const url = this.url + `/${id}/GetSliFile`
    let headers = new HttpHeaders()
    headers = headers.set('Accept', `application/${type}`)
    return this.http.get(url, { headers: headers, responseType: 'blob' })
  }

  getConfigurationSummary(estimateId: number) {
    const url = this.url + `/${estimateId}/EstimateProducts/ConfigurationSummary`
    let headers = this.helperService.setLanguageHeaders()
    headers = headers.set('Accept', `application/zip`)
    return this.http.get(url, {
      headers: headers,
      responseType: 'blob',
      observe: 'response'
    })
  }

  public getEstimatesByQuoteNumber(quoteNumber: string): Observable<Estimate[]> {
    const url = this.url + `/GetEstimatesByQuoteNumber/${quoteNumber}`
    return <Observable<Estimate[]>>this.http.get(url)
  }

  uploadShippersLetterOfInstructionFile(estimateId: number, shippersLetterOfInstructionFile: File) {
    const url = environment.onlineOrderingApiUrl + 'Estimates/' + estimateId + '/UploadShippersLetterOfInstruction'
    const formData: FormData = new FormData()
    formData.append('estimateShippersLetterOfInstructionUploadRequestJson', JSON.stringify({ estimateId: estimateId }))
    formData.append('shippersLetterOfInstructionFile', shippersLetterOfInstructionFile)
    return firstValueFrom(this.http.post(url, formData))
  }

  setEstimateProductDoorMarkings(
    estimateId: number,
    estimateProductId: number,
    payload: { name: string; quantity: number }[]
  ): Observable<Estimate> {
    const url = this.url + `/${estimateId}/EstimateProducts/${estimateProductId}/DoorMarkings`
    return this.http.post(url, payload).pipe(mergeMap(() => this.getCleanEstimate(estimateId)))
  }

  setEstimateHardwareSetDoorMarkings(
    estimateId: number,
    estimateHardwareSetId: number,
    payload: { name: string; quantity: number }[]
  ): Observable<Estimate> {
    const url = this.url + `/${estimateId}/EstimateHardwareSets/${estimateHardwareSetId}/DoorMarkings`
    return this.http.post(url, payload).pipe(mergeMap(() => this.getCleanEstimate(estimateId)))
  }

  //  Hollow Metal line item attachements - Products

  addProductLineAttachment(
    estimateId: number,
    estimateProductId: number,
    file: File
  ): Observable<EstimateProductAttachment[]> {
    const formData = new FormData()
    formData.append('attachmentRequestJson', JSON.stringify({ estimateId, estimateProductId }))
    formData.append('attachmentFile', file)
    const url = this.url + `/${estimateId}/EstimateProducts/${estimateProductId}/Attachments`
    return this.http
      .post<EstimateProductAttachment[]>(url, formData)
      .pipe(tap((_) => this.getCleanEstimate(estimateId).pipe(take(1)).subscribe()))
  }

  getProductLineAttachments(estimateId: number, estimateProductId: number): Observable<EstimateProductAttachment[]> {
    const url = this.url + `/${estimateId}/EstimateProducts/${estimateProductId}/Attachments`
    return this.http.get<EstimateProductAttachment[]>(url)
  }

  getProductLineAttachment(
    estimateId: number,
    estimateProductId: number,
    estimateProductAttachmentId: number
  ): Observable<string> {
    const url =
      this.url + `/${estimateId}/EstimateProducts/${estimateProductId}/Attachments/${estimateProductAttachmentId}`
    const headers = new HttpHeaders()
    return this.http.get(url, { headers, responseType: 'arraybuffer' }).pipe(
      map((arrayBuffer) => {
        const file = new Blob([arrayBuffer], { type: 'application/pdf' })
        return URL.createObjectURL(file)
      })
    )
  }

  deleteProductLineAttachment(
    estimateId: number,
    estimateProductId: number,
    estimateProductAttachmentId: number
  ): Observable<EstimateProductAttachment[]> {
    const url =
      this.url + `/${estimateId}/EstimateProducts/${estimateProductId}/Attachments/${estimateProductAttachmentId}`
    return this.http
      .delete<EstimateProductAttachment[]>(url)
      .pipe(tap((_) => this.getCleanEstimate(estimateId).pipe(take(1)).subscribe()))
  }

  getTotalDoorMarkings(array: Partial<DoorMarking>[] | Partial<EstimateProductDoorMarking>[]): number {
    let total = 0
    array.forEach((door) => (total = total + Number(door.quantity)))
    return total
  }

  checkForNonADSProductsCheck() {
    const hasNonADSProducts = this.estimate()?.products?.some((estimateProduct: EstimateProduct) => {
      return estimateProduct?.product?.brand !== 'ADS'
    })
    const hasNonADSonHWS = this.estimate()?.hardwareSets?.some((estimateHardwareSet: EstimateHardwareSet) => {
      return estimateHardwareSet.hardwareSet?.products.some((product) => {
        return product.brand !== 'ADS'
      })
    })
    const hasNonADS = (hasNonADSProducts ?? false) || (hasNonADSonHWS ?? false)
    return hasNonADS
  }

  hasNonSpecifiedHanding() {
    return this.estimate()?.products?.some((estimateProduct: EstimateProduct) => {
      return estimateProduct.product?.handing === 'Not Specified'
    })
  }

  allLineItemsHaveDoorMarkings() {
    const estimate = this.estimate()
    if (!estimate?.products) return true
    return estimate.products?.reduce((allProductsmarked, estimateProduct) => {
      const qtyMatchesDoorMarksTotal =
        estimateProduct.quantity === this.getTotalDoorMarkings(estimateProduct.estimateProductDoorMarkings ?? [])
      return allProductsmarked && qtyMatchesDoorMarksTotal
    }, true)
  }

  addProductFromElevation(estimateId: number, configXml: string, estimateProductId?: number) {
    const url = this.url + `/${estimateId}/EstimateProducts/Elevations`
    const payload = { configXml, estimateProductId }
    return this.http.post(url, payload).pipe(mergeMap(() => this.getCleanEstimate(estimateId)))
  }

  checkQuoteUsed(estimate: Estimate): Observable<boolean> {
    if (estimate.quote) {
      const quote = estimate.quote.quoteNumber
      return this.poService.quotesAppliedToPurchaseOrder([quote]).pipe(
        distinctUntilChanged(),
        map((res: string[]) => {
          return res.length === 0 ? false : res[0] === quote
        })
      )
    } else {
      return of(false)
    }
  }

  public checkForADSErrors(estimate: Estimate) {
    if (!this.isADSEstimate(estimate)) {
      this._adsErrors.next(new ADSEstimateErrors())
      return of()
    }
    return forkJoin({
      quoteUsed: this.checkQuoteUsed(estimate),
      estimateHasAssociatedPo: this.poService.getPurchaseOrdersForEstimate(estimate.id)
    }).pipe(
      tap(({ quoteUsed, estimateHasAssociatedPo }) => {
        const errors = new ADSEstimateErrors()
        if (quoteUsed) {
          errors.quoteHasPOAssociated = true
        }
        if (estimate.customQuoteRequired) {
          errors.customQuoteRequired = true
        }
        if (this.checkForNonADSProductsCheck()) {
          errors.hasNonADSProducts = true
        }
        if (this.hasNonSpecifiedHanding() || !this.allLineItemsHaveDoorMarkings()) {
          errors.missingHandingOrDoorMarkings = true
        }
        if (
          estimate.customADSFreightRequired &&
          (estimate.adsFreightTotalCost === 0 || !estimate.adsFreightTotalCost)
        ) {
          errors.customFreightRequired = true
        }
        if (estimateHasAssociatedPo.length > 0) {
          errors.adsEstimateHasAssociatedPo = true
        }
        this._adsErrors.next(errors)
      })
    )
  }

  public updateEstimateProductHanding(
    estimateId: number,
    estimateProductId: number,
    productId: number,
    handing: string
  ): Observable<Estimate> {
    this._loading.next(true)
    const updatedProductObj = {
      productId: productId,
      handing: handing
    }
    const url = this.url + `/${estimateId}/EstimateProducts/${estimateProductId}/Products/${productId}`
    return this.http.put(url, updatedProductObj).pipe(
      // Get the updated price based on quantity
      tap((res: Estimate) => {
        this._estimate.next(res)
        this.helperService.openAlert({ title: 'SUCCESSES.productHandingUpdate', state: 'success' })
        this._loading.next(false)
      }),
      catchError((err: any) => {
        this.getCleanEstimate()
        return of(err)
      })
    )
  }

  generateURLParameters(searchAndFilters: SearchAndFilters) {
    let params = `?pageSize=${this.params.pageSize}&skip=${this.params.skip}`
    params += `&orderBy=${this.params.property}|${this.params.direction?.toUpperCase()}`
    if (searchAndFilters.search) {
      params += `&searchFilters=${searchAndFilters.search || null}`
    }
    if (searchAndFilters.activeFilters && Object.keys(searchAndFilters.activeFilters).length > 0) {
      params += `&queryFilters=`
      const filterKeys = Object.keys(searchAndFilters.activeFilters)
      filterKeys.forEach((key, index) => {
        const value: string = searchAndFilters.activeFilters[key]
        if (key === 'lastModifiedByUserName') {
          key = 'ModifiedBy.Name'
        } else if (key === 'quote.quoteNumber') {
          key = 'quoteNumber'
        }
        params += `${key}`
        params += `|${value}`
        if (filterKeys.length - 1 !== index) {
          params += ';'
        }
      })
    }
    return params
  }

  getEstimateProductConfigXML(productId: number): Observable<string> {
    const url = environment.onlineOrderingApiUrl + `Products/${productId}/GetConfigXML`
    return this.http.get<string>(url)
  }

  containsNewlyAddedProducts(estimate: Estimate): boolean {
    return estimate.products?.some((product) => isRecentlyAddedItem(product))
  }
}
