import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ErrorHandler, NgModule } from '@angular/core'

import { AuthModule } from './auth/auth.module'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { CoreModule } from './core/core.module'
import { SharedModule } from './shared/shared.module'
import { UnauthorizedComponent } from './unauthorized/unauthorized.component'
import { DetachedCustomerAccountComponent } from './detached-customer-account/detached-customer-account.component'
import { ElevationModule } from '@oeo/ovt-elevations'
import { ElevationsComponent } from './elevations/elevations.component'
import { environment } from '../environments/environment'
import { AppInsightsConfig, AppInsightsErrorHandler, AppInsightsModule } from '@overtur/app-insights'
import { CustomerService } from './services/customer/customer.service'
import { APP_BASE_HREF } from '@angular/common'

const appInsightsConfig: AppInsightsConfig = {
  appInsightsInstrumentationKey: environment.appInsights.instrumentationKey,
  appInsightsCorrelationDomains: environment.appInsights.correlationDomains
}
@NgModule({
  declarations: [AppComponent, UnauthorizedComponent, DetachedCustomerAccountComponent, ElevationsComponent],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    ElevationModule.forRoot(environment, CustomerService),
    SharedModule,
    AppInsightsModule.forRoot({ appInsightsEnv: appInsightsConfig })
  ],
  providers: [
    CustomerService,
    { provide: 'appInsightsEnv', useValue: appInsightsConfig },
    { provide: ErrorHandler, useClass: AppInsightsErrorHandler },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
