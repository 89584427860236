import { HelperService } from '../services/helper/helper.service'
import { KeyedGroup } from './keyedGroups.model'
import { DoorMarking } from './door-marking'
import { Address } from '../models/address.model'
import { DiscountType, Product } from './product.model'
import { Tooltip } from './Tooltip'

export class PurchaseOrderLineItem {
  acn?: string
  id: number
  purchaseOrderId: number
  lineNumber: number
  sku: number
  proExpress: boolean
  description: string
  purchaseOrderLineItemDoorMarkings?: DoorMarking[]
  finish: string
  quantity: number
  listPrice: number
  buyingProgramName: string
  quoteCategoryName: string
  productId: number
  effectiveTotalPrice: number
  effectiveUnitPrice: number
  brand: string
  product: Product
}

export class PurchaseOrder {
  additionalRCKQuantity: number
  batch?: string
  billingAddress: Address
  billingAddressId: number
  buyerEmailAddress: string
  buyerFaxNumber: number
  buyerName: string
  buyerPhoneNumber: number
  ciFile?: File
  companyAccountNumber: string
  doNotShipBeforeDate?: string
  effectiveTotalPrice?: number
  elockRegistration: boolean
  id: number
  includeDoorLabel?: boolean
  installationAddress?: Address
  installationContactName?: string
  keyedGroups: KeyedGroup[]
  lastModified: string
  leadTimeBusinessDays: string
  lineItems: PurchaseOrderLineItem[]
  packingSlipInstructions: string
  pacsPartnerName: string
  projectCity: string
  projectName: string
  projectRegistrationNumber: string
  projectState: string
  purchaseOrderDate: string
  purchaseOrderNumber: string
  purchaseOrderSplitCategory: PurchaseOrderSplitCategory
  purchaseOrderSplitCategoryId: number
  purchaseOrderSplitCategoryName?: string
  quoteNumber: number
  rckShippingAddressId: number
  requestedShipDate?: string
  residentialConstructionKeyed: boolean
  shipComplete: boolean
  shippingAddress: Address
  shippingAddressId: number
  shippingComments?: string
  shippingMethod: string
  shippingMethodId: number
  shippingRemarks: string
  shippingTerms: string
  shipProExpress?: boolean
  status: string
  supplierNumber: number
  supportingDocumentation?: File
  userId: number
  createdUserName: string
}
export class ExtendedLineItem extends PurchaseOrderLineItem {
  doorMarksString?: Tooltip
  purchaseOrderSplitCategoryId?: number
  proExpressSku?: string
  discountType?: DiscountType
  quoteApplied?: boolean
}

export class ExtendedPurchaseOrder extends PurchaseOrder {
  country?: string
  lineItems: ExtendedLineItem[]
  shipProExpress?: boolean
  shipFastTrack?: boolean
  invalid?: boolean
  poNumberExists?: boolean
  structureNumber?: string
  shippingDeliveryContact?: string
  rckDeliveryContact?: string
  shippingCommentsToCarrier?: string
  specialInstructions?: string
  hasSpecialInstructions?: boolean
  prnInvalid?: boolean
  pacsPartnerInvalid?: boolean
  elockPrnOption?: string
  indirectPurchaseOrderExtendedData?: {
    id: number
    purchaseOrderId: number
    termsAndConditions?: string
    locksmithIdNumber?: string
    vendorCompanyName?: string
    quoteNumber?: string
    vendorContactName?: string
    vendorEmailAddress?: string
    orderEmailSubjectLine?: string
    message?: string
    isElockConfigAttached: boolean
    shippingTerms?: string
    hasSpecialInstructions?: boolean
  }
  includeDoorFrameServiceInstructions?: boolean
  doorFrameServiceInstructions?: string
  adsTermsCheck?: boolean
  hasFrameElevation?: boolean
  hasRestrictedProducts?: boolean
}
export class ViewPO extends PurchaseOrder {
  type: string
  shortAddress: string
  actions: boolean
  downloading: boolean
  displayDate: string
  orderType: string
  orderedBy: string
  constructor(purchaseOrder: PurchaseOrder) {
    super()
    Object.assign(this, purchaseOrder)
    this.shortAddress = `${purchaseOrder.shippingAddress.line1}, ${purchaseOrder.shippingAddress.postalCode}`
    this.actions = true
    const formatDate = HelperService.prototype.formatDate
    this.displayDate = formatDate(new Date(purchaseOrder.purchaseOrderDate))
    this.orderType = purchaseOrder?.purchaseOrderSplitCategory?.name || ''
    this.orderedBy = purchaseOrder?.createdUserName
  }
}

export class PurchaseOrderSplitCategory {
  id: number
  name: string
  addressee: string
  addressLine1: string
  addressLine2: string
  city: string
  stateProvince: string
  postalCode: string
  country: string
  phoneNumber: string
  faxNumber: string
}

export class DummyPoResponse {
  orderId: number
  orderNumber: string
}
