<input
  *ngIf="column && row"
  [(ngModel)]="data"
  (ngModelChange)="handleChange(data, $event)"
  (click)="stopPropagation($event)"
  [disabled]="column?.validators?.disabled ?? false"
  [attr.minlength]="column?.validators?.minlength"
  [attr.maxlength]="column?.validators?.maxlength"
  [pattern]="column?.validators?.pattern ?? '.*'"
  type="string"
  [required]="column?.validators?.required ?? false"
  [ngClass]="{ duplicate: row?.duplicate }"
  oaUpperCase
/>
<mat-error *ngIf="row?.duplicate">{{ 'VALIDATION.unique' | translate }}</mat-error>
