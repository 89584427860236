import { Component, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'

import { Subject, combineLatest, filter, mergeMap, of, take, takeUntil } from 'rxjs'

import { environment } from '../../../../environments/environment'
import { CustomerAccount } from '../../../models'
import { Features } from '../../../models/features.model'
import { CustomerService } from '../../../services/customer/customer.service'
import { FlagsService } from '../../../services/flags/flags.service'
import { HelperService } from '../../../services/helper/helper.service'
import { StorageService } from '../../../services/local-storage/local-storage.service'

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnDestroy {
  destroyed$: Subject<boolean> = new Subject()
  flags!: Features

  get showCustomerSelection(): boolean {
    return !!this.storageService.getItem('parentCompanyId')
  }

  get interalUser(): boolean {
    return this.storageService.getItem('internal')
  }

  get showCustomerSelectionNotification(): boolean {
    return (
      (this.flags || this.storageService.getItem('internal')) &&
      (!this.storageService.containsItem('parentCompanyId') ||
        !this.storageService.containsItem('targetBranchCustomerId'))
    )
  }

  multiBranchEnabled = environment.featureFlags.enableMultiBranch

  constructor(
    private router: Router,
    public flagsService: FlagsService,
    public helperService: HelperService,
    private storageService: StorageService,
    public customerService: CustomerService
  ) {
    this.customerService.unauthorized$.subscribe((unauthorized) => {
      if (unauthorized) {
        this.router.navigate(['/unauthorized'])
      }
    })

    this.flagsService.flags$
      .pipe(
        filter((x) => !!x),
        /* Make sure the targetBranchCustomerId is set before routing */
        mergeMap((flags) =>
          combineLatest([of(flags), flags.enableMultiBranch ? this.customerService.targetBranchCustomerId$ : of(null)])
        ),
        take(1)
      )
      .subscribe({
        next: ([flags]) => {
          this.flags = flags
          if (flags.isDirect) {
            this.router.navigate(['/estimates'], { onSameUrlNavigation: 'reload' })
          } else {
            this.router.navigate(['/projects'], { onSameUrlNavigation: 'reload' })
          }
        },
        error: (error) => this.helperService.handleError(error.message)
      })

    if (!this.storageService.getItem('internal')) {
      this.customerService.customerBranches$.pipe(takeUntil(this.destroyed$)).subscribe((branches) => {
        this.targetBranch = branches.find((branch) => branch.accountId === this.customerService.parentCompanyId)
      })
    }
  }

  targetBranch: CustomerAccount = this.customerService.parentCompany
  setDefaultBranchOnLogin = false

  selectCompany(company: CustomerAccount) {
    this.customerService.parentCompany = company
    this.targetBranch = company
  }

  selectBranch() {
    if (this.setDefaultBranchOnLogin) {
      this.customerService.defaultBranchOnLogin = this.targetBranch.accountId
    }
    this.customerService.selectBranch(this.targetBranch.accountId)
    this.flagsService.flags$
      .pipe(
        filter((x) => !!x),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (flags) => {
          if (flags.isDirect) {
            this.router.navigate(['/estimates'], { onSameUrlNavigation: 'reload' })
          } else {
            this.router.navigate(['/projects'], { onSameUrlNavigation: 'reload' })
          }
        },
        error: (error) => this.helperService.handleError(error.message)
      })
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
