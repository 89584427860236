<div>
  <ng-container *ngIf="!column.icons; else multipleIcons">
    <ng-container *ngTemplateOutlet="iconTemplate; context: { icon: column }"> </ng-container>
  </ng-container>
</div>

<ng-template #multipleIcons>
  <ng-container *ngFor="let icon of column.icons">
    <ng-container *ngTemplateOutlet="iconTemplate; context: { icon }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #iconTemplate let-icon="icon">
  <mat-icon
    *ngIf="showIcon(row, icon?.property)"
    [color]="icon?.color"
    [svgIcon]="icon.materialIcon ? '' : icon.icon"
    [matTooltip]="icon?.tooltip"
    data-testid="icon-element"
    >{{ icon.materialIcon ? icon.icon : '' }}</mat-icon
  >
</ng-template>
