import { EstimateHardwareSet } from './estimateHardwareSet.model'
import { EstimateProduct } from './estimateProduct.model'
import { Quote } from './quote'
import { EstimateProductDiscount } from '../models/product-discount.model'
import { EstimateDefault } from './estimate-default.model'
import { FilteredType, DropdownOption } from '@oeo/common'

export class Estimate {
  id?: number
  name?: string
  created?: string
  lastModified?: string
  createdByUserId?: number
  customADSFreightRequired?: boolean
  customQuoteRequired?: boolean
  adsFreightTotalCost?: number
  adsAluminumFreightCost?: number
  adsWoodFreightCost?: number
  lastModifiedByUserId?: number
  lastModifiedByUserName?: string
  quoteNumber?: string
  products?: EstimateProduct[]
  hardwareSets?: EstimateHardwareSet[]
  estimateType?: {
    id: EstimateType
    name: string
  }
  estimateTypeId?: EstimateType
  quote?: Quote
  productDiscounts?: EstimateProductDiscount[]
  shipToStateCode?: string
  //  HollowMetal
  default?: EstimateDefault
  isDeleted?: boolean
  freightAdjusted?: boolean
  hasNotification?() {
    return this.lastModifiedByUserId !== this.createdByUserId
  }
  hasRepublicProducts?: boolean
  shippersLetterOfInstruction?: string
  rePrice?: boolean
  quoteApplied?: boolean
  crossReferenceId?: number
}

export interface ExtendedEstimate extends Estimate {
  actions: string
  quoteNumber: string
  formattedDate: string
  quoteExpirationDate: string
  quoteStatus: string
}

export enum EstimateType {
  Standard = 1,
  ProExpress,
  ADSystems
}

export enum DoorHanding {
  HAND_NS = 'Not Specified',
  HAND_OR = 'Opens Right',
  HAND_OL = 'Opens Left',
  HAND_ORR = 'Opens Right Reverse',
  HAND_OLR = 'Opens Left Reverse'
}

export function getDoorHandings() {
  const doorHandings = Object.keys(DoorHanding).map((key: keyof typeof DoorHanding) => {
    return { text: DoorHanding[key], value: key } as DropdownOption
  })
  return doorHandings
}

export class ADSEstimateErrors {
  hasNonADSProducts: boolean = false
  missingHandingOrDoorMarkings: boolean = false
  quoteHasPOAssociated: boolean = false
  customFreightRequired: boolean = false
  customQuoteRequired: boolean = false
  adsEstimateHasAssociatedPo: boolean = false

  errors() {
    return Object.keys(this).filter((property: FilteredType<ADSEstimateErrors, boolean>) => this[property])
  }

  length() {
    return this.errors().length
  }

  hasErrors() {
    return this.length() > 0
  }
}
