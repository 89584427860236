import { Component, inject } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { EstimatesDialogComponent } from '../../../estimates/components/estimates-dialog/estimates-dialog.component'
import { CreateNewDialogComponent } from './create-new-dialog/create-new-dialog.component'
import { IndirectNewProjectDialogComponent } from './create-new-dialog/indirect-new-project-dialog/indirect-new-project-dialog.component'

import { Features, CustomerType } from '../../../models/features.model'
import { FlagsService } from '../../../services/flags/flags.service'
import { Observable } from 'rxjs'
import { User } from '@auth0/auth0-spa-js'
import { AuthService } from '../../../services/auth.service'
import { UserOptionsDialogComponent } from '../user-options-dialog/user-options-dialog.component'
import { StorageService } from '../../../services/local-storage/local-storage.service'
import { CustomerService } from '../../../services/customer/customer.service'

type OEORoute = {
  label: string
  path: string
  show: () => boolean
  svgIcon: string
}
@Component({
  selector: 'oa-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
  isCollapsed = this.storageService.containsItem('collapsedNav') ? this.storageService.getItem('collapsedNav') : false
  authorized = false
  flags: Features
  customerType = CustomerType
  user$: Observable<User>
  constructor(
    public dialog: MatDialog,
    public flagsService: FlagsService,
    private storageService: StorageService,
    public customerService: CustomerService
  ) {
    this.flagsService.flags$.subscribe((flags) => {
      if (!flags) {
        return
      }
      this.flags = flags
      this.authorized = flags.isDirect || flags.isIndirect || flags.proExpress
    })
    this.user$ = inject(AuthService).userProfile$
  }

  routes: Array<OEORoute> = [
    {
      label: 'estimates',
      path: 'estimates',
      show: () => this.flags?.isDirect,
      svgIcon: 'estimates'
    },
    {
      label: 'projects',
      path: 'projects',
      show: () => this.flags?.isIndirect,
      svgIcon: 'estimates'
    },
    {
      label: 'hardwareSets',
      path: 'hardware-sets',
      show: () => this.flags?.hardwareSets,
      svgIcon: 'hardware-sets'
    },
    {
      label: 'crossReference',
      path: 'cross-reference',
      show: () => this.flags?.crossReferences,
      svgIcon: 'cross-reference'
    },
    {
      label: 'quotes',
      path: 'quotes',
      show: () => this.flags?.quotes,
      svgIcon: 'quotes'
    },
    {
      label: 'orderHistory',
      path: 'orderhistory',
      svgIcon: 'orders',
      show: () => true
    }
  ]

  createNewDialog() {
    if (this.flags.isDirect && this.flags.proExpress) {
      this.dialog.open(CreateNewDialogComponent)
    } else if (this.flags.isIndirect) {
      this.dialog.open(IndirectNewProjectDialogComponent, { minWidth: '40%', autoFocus: false, disableClose: true })
    } else {
      this.dialog.open(EstimatesDialogComponent, { data: { action: 'create' }, width: '50vw' })
    }
  }

  onToggleCollapse() {
    this.isCollapsed = !this.isCollapsed
    this.storageService.setItem('collapsedNav', this.isCollapsed)
  }

  openUserOptionsDialog() {
    this.dialog.open(UserOptionsDialogComponent)
  }
}
